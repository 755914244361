import React, { useState } from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { populateFeeScheduleStatusOptions } from '../../utils/sale.type.helper'
import { Button } from '@interstate/components/Button'
import { PatTextInput } from '../common/PatTextInput'
import { PatDatePicker } from '../common/PatDatePicker'
import { PatSelectInput } from '../common/PatSelectInput'

export function FeeScheduleStatusSearch({
    customerId,
    defaultStartDate,
    defaultEndDate,
    defaultFeeScheduleStatus
}) {
    const navigate = useNavigate()

    const [groupCodeOrAccountNumber, setGroupCodeOrAccountNumber] =
        useState(customerId)
    const [startDate, setStartDate] = useState(defaultStartDate)
    const [endDate, setEndDate] = useState(defaultEndDate)
    const [feeScheduleStatus, setFeeScheduleStatus] = useState(
        defaultFeeScheduleStatus
    )

    const feeScheduleStatusOptions = populateFeeScheduleStatusOptions()

    function handleInputChange(event) {
        switch (event.target.name) {
            case 'groupCodeOrAccountNumber':
                setGroupCodeOrAccountNumber(event.target.value)
                break
            case 'startDate':
                setStartDate(event.target.value)
                break
            case 'endDate':
                setEndDate(event.target.value)
                break
            case 'feeScheduleStatus':
                setFeeScheduleStatus(event.target.value)
                break
            default:
                break
        }
    }

    function searchFeeSchedule() {
        const queryParams = {
            q: groupCodeOrAccountNumber,
            startDate: startDate ? startDate : '',
            endDate: endDate ? endDate : '',
            feeScheduleStatus: feeScheduleStatus
        }
        navigate({
            pathname: '/boc/feeScheduleStatusResults',
            search: `?${createSearchParams(queryParams)}`
        })
    }

    return (
        <>
            <PatTextInput
                name="groupCodeOrAccountNumber"
                label="Group code / Account number"
                value={groupCodeOrAccountNumber}
                onChange={handleInputChange}
            />
            <PatDatePicker
                onChange={handleInputChange}
                name="startDate"
                label="Start date"
                value={startDate}
            />
            <PatDatePicker
                onChange={handleInputChange}
                name="endDate"
                label="End date"
                value={endDate}
            />
            <PatSelectInput
                name="feeScheduleStatus"
                value={feeScheduleStatus}
                label="Status"
                options={feeScheduleStatusOptions}
                onChange={handleInputChange}
            />

            <Button
                onClick={searchFeeSchedule}
                sx={{ marginTop: '10px', marginBottom: '20px' }}>
                Search
            </Button>
        </>
    )
}
