import { HashRouter, Routes, Route } from 'react-router-dom'
// import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/pat_admin.css'
import { SpinnerInterceptor } from './interceptors/SpinnerInterceptor'
import { AuthContextLoader } from './auth/AuthProvider'
import { AuthInterceptor } from './interceptors/AuthInterceptor'
import { LoginView } from './views/login/LoginView'
import ProtectedLayout from './layout/ProtectedLayout'
import HomeLayout from './layout/HomeLayout'
import BocRoute from './routes/BocRoute'
import ConfigRoute from './routes/ConfigRoute'
import DevRoute from './routes/DevRoute'
import { MainPageView } from './views/MainPageView'

function App() {
    return (
        <div className="App">
            <HashRouter basename="/">
                <SpinnerInterceptor />
                <AuthContextLoader>
                    <AuthInterceptor />
                    <Routes>
                        <Route path="/" element={<HomeLayout />}>
                            <Route path="/" element={<LoginView />} />
                        </Route>
                        <Route path="/main" element={<ProtectedLayout />}>
                            <Route path="" element={<MainPageView />} />
                        </Route>
                        <Route path="/boc" element={<ProtectedLayout />}>
                            <Route path="*" element={<BocRoute />} />
                        </Route>
                        <Route path="/config" element={<ProtectedLayout />}>
                            <Route path="*" element={<ConfigRoute />} />
                        </Route>
                        <Route path="/dev" element={<ProtectedLayout />}>
                            <Route path="*" element={<DevRoute />} />
                        </Route>
                        <Route path="*" element={<ProtectedLayout />}>
                            <Route
                                path="*"
                                element={
                                    <div>
                                        You have traveled into somewhere that is
                                        nowhere.
                                    </div>
                                }
                            />
                        </Route>
                    </Routes>
                </AuthContextLoader>
            </HashRouter>
        </div>
    )
}

export default App
