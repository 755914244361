import React, { useEffect, useState } from 'react'
import { getVehicleAuctionSblu } from '../../service/auctionVehicleDataService'
import { getPossibleFeeSchedules } from '../../service/feeScheduleService'
import { VehicleNotesTable } from '../VehicleNotesTable'
import { PossibleFeeTable } from '../tables/PossibleFeeTable'
import { CalculatedFeeTable } from '../tables/CalculatedFeeTable'
import { AuctionVehicleDataTable } from '../tables/AuctionVehicleDataTable'
import { ManualFeesForm } from '../forms/ManualFeesForm'

export function IndividualAudit({ data }) {
    const [initData, setInitData] = useState(null)
    const [vehicleData, setVehicleData] = useState({})
    const [feeSchedules, setFeeSchedules] = useState([])
    const [highlightedRow, setHighlightedRow] = useState([])

    useEffect(() => {
        if (data && data.length > 0) {
            setInitData(data[0])
        }
    }, [data])

    useEffect(() => {
        if (!initData) return
        setInitData(data[0])
        let av = initData?.buCode
        let sblu = initData?.sblu
        getVehicleAuctionSblu(av, sblu)
            .then((d) => {
                setVehicleData(d)
            })
            .catch((error) => {
                setVehicleData(error.response.data)
            })

        let highlight = []

        data.forEach((fee) => {
            if (!highlight.includes(fee.feeSchedulePartId)) {
                highlight.push(fee.feeSchedulePartId)
            }
        })
        setHighlightedRow(highlight)
    }, [initData, data])

    useEffect(() => {
        if (!initData) return

        let av = initData?.buCode

        let customerIds = [
            initData?.seller,
            initData?.buyer,
            initData?.sellerGroup,
            initData?.buyerGroup,
            initData?.sellerSubGroup,
            initData?.buyerSubGroup
        ]
        if (vehicleData?.body?.saleDate) {
            getPossibleFeeSchedules(
                av,
                customerIds,
                initData.saleType,
                vehicleData?.body?.saleDate
            )
                .then((d) => {
                    setFeeSchedules(d)
                })
                .catch((error) => {
                    setFeeSchedules(error?.response?.data)
                })
        }
    }, [initData, vehicleData])
    // console.log(vehicleData)
    return (
        <>
            <AuctionVehicleDataTable
                initData={initData}
                vehicleData={vehicleData}
            />

            <CalculatedFeeTable data={data} />

            <ManualFeesForm bucode={initData?.buCode} sblu={initData?.sblu} />

            <h2>Eligible Seller Fees</h2>
            <PossibleFeeTable
                fees={feeSchedules}
                filterCriteria={['SSELL', 'SELLER', 'SLIST']}
                highlightedRow={highlightedRow}
            />

            <h2>Eligible Buyer Fees</h2>
            <PossibleFeeTable
                fees={feeSchedules}
                filterCriteria={[
                    'BUYER',
                    'BLIST',
                    'BBUY',
                    'SELLBUY',
                    'SELLBYS'
                ]}
                highlightedRow={highlightedRow}
            />

            {vehicleData?.body?.saleChannel === 'Z' && (
                <>
                    <h2>Eligible Simulcast Fees</h2>
                    <PossibleFeeTable
                        fees={feeSchedules}
                        filterCriteria={[
                            'INTBUY',
                            'INTSELL',
                            'SIMBFE',
                            'SIMBUY'
                        ]}
                        highlightedRow={highlightedRow}
                    />
                </>
            )}

            {initData && (
                <>
                    <h2>Vehicle Notes</h2>
                    <VehicleNotesTable
                        auction={initData?.buCode}
                        workOrderNumber={initData.workOrderNumber}
                    />
                </>
            )}
        </>
    )
}
