import React from 'react'
import { CSVLink } from 'react-csv'

export function CsvExport({ csvData, fileName }) {
    return (
        <>
            {csvData &&
                csvData.feeSchedeuleFlatListForCsv &&
                csvData.feeSchedeuleFlatListForCsv.length > 0 && (
                    <CSVLink
                        data={csvData.feeSchedeuleFlatListForCsv}
                        headers={csvData.csvHeaderKeys}
                        filename={fileName}
                        className="ids-btn"
                        data-ids-variant="primary">
                        Export CSV
                    </CSVLink>
                )}
        </>
    )
}
