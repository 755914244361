import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import axios from 'axios'
import { Alert } from '@interstate/components/Alert'
import { DefaultAppRedirect } from '../default/DefaultAppRedirect'
import { useAuth } from '../../auth/AuthProvider'
import {
    useAsyncValue,
    useManualRerenderWithVersion
} from '../../utils/CustomHooksUtil'
import { isDevEnv } from '../../utils/environments'
import { TextInput } from '@interstate/components/TextInput'
import { Container } from '../../components/Container'
import { CheckBox } from '@interstate/components/CheckBox'
import { Button } from '@interstate/components/Button'
import { PageHeader } from '../../components/PageHeader'
import { SelectInput } from '@interstate/components/SelectInput'
import { PatTextInput } from '../../components/common/PatTextInput'
import { MockUserForm } from '../../components/MockUserForm'

const FACTORS_NEED_TOKEN_GENERATION = new Set(['sms', 'call'])

function useFactorAnswerGeneration(factorId, authService) {
    return useAsyncValue(() => {
        if (factorId) {
            const factors = authService
                .factors()
                .filter(({ value }) => value === factorId)
            if (
                factors &&
                factors.length > 0 &&
                FACTORS_NEED_TOKEN_GENERATION.has(factors[0].factorType)
            ) {
                return axios.get(`/auth/generate/answer/${factorId}`)
            }
        }
        return Promise.resolve({})
    }, [factorId])
}

function useVerifyPushFactor(
    pushSent,
    factorId,
    resend,
    version,
    updateVersion
) {
    return useAsyncValue(() => {
        if (pushSent) {
            return axios
                .get(`/auth/poll/factor/${factorId}`, {
                    headers: { hideSpinner: true },
                    params: { resend }
                })
                .then(({ data }) => {
                    if (data === 'WAITING') {
                        setTimeout(updateVersion, 2000)
                    } else if (data !== 'SUCCESS') {
                        return Promise.reject(data)
                    }
                    return data
                })
        }
        return Promise.resolve('PUSH_NOT_SENT')
    }, [pushSent, factorId, version, updateVersion])
}

export function logout() {
    axios.post('/auth/logout').then(() => {
        window.location.reload(false)
    })
}

export function LoginView() {
    const [selectedFactor, setSelectedFactor] = useState('')
    const [pushSent, setPushSent] = useState(false)
    const [pushErrorStatus, setPushErrorStatus] = useState(null)
    const authService = useAuth()
    const factorAnswerTextRef = useRef()
    const [version, updateVersion] = useManualRerenderWithVersion()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [factorAnswer, setFactorAnswer] = useState(null)

    const { loading: factorAnswerGenerating, error: factorAnswerFailed } =
        useFactorAnswerGeneration(selectedFactor, authService)

    const onPushSend = useCallback(() => {
        setPushSent(true)
    }, [setPushSent])

    const pushFactorSelected = useMemo(() => {
        return (
            !!selectedFactor &&
            authService.factors().find(({ value }) => value === selectedFactor)
                .factorType === 'push'
        )
    }, [selectedFactor, authService])

    const { error: pushError, value: pushStatus } = useVerifyPushFactor(
        pushSent,
        selectedFactor,
        !!pushErrorStatus,
        version,
        updateVersion
    )

    useEffect(() => {
        if (pushSent && pushError) {
            setPushSent(false)
            setPushErrorStatus(pushError)
            setTimeout(logout, 2000)
        }
    }, [pushError, pushSent])

    useEffect(() => {
        if (pushSent && pushStatus === 'SUCCESS') {
            window.location.reload(false)
        }
    }, [pushSent, pushStatus])

    if (authService.isAuthenticated()) {
        return <DefaultAppRedirect />
    }

    function onFactorChange(e) {
        setSelectedFactor(e.target.value)
        if (
            e.target.value &&
            factorAnswerTextRef &&
            factorAnswerTextRef.current
        ) {
            factorAnswerTextRef.current.focus()
        }
    }

    return (
        <Container>
            {!authService.hasStateToken() && (
                <form method="POST" action="/auth/login/password">
                    <PageHeader titleValue="Sign In" />

                    <PatTextInput
                        id="email"
                        label="Email address"
                        name="email"
                        data-testid="email"
                        placeholder="email"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                    />
                    <input type="hidden" name="username" value={username} />

                    <PatTextInput
                        id="passwprd"
                        label="Password"
                        name="pass"
                        data-testid="password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                    <input type="hidden" name="password" value={password} />

                    {/*<label style={labelStyle}>Password</label>*/}
                    {/*<input type="password"*/}
                    {/*       data-testid="password"*/}
                    {/*       onChange={(e) => setPassword(e.target.value)}*/}
                    {/*       style={inputStyle}*/}
                    {/*       name="pass"*/}
                    {/*       value={password}/>*/}

                    <CheckBox
                        id="checkbox-1-id"
                        label="Remember this device"
                        name="rememberMe"
                        sx={{
                            margin: '18px'
                        }}
                        value="1"
                    />

                    <Button buttonStyle="primary" type="submit">
                        Sign In
                    </Button>
                    <br />
                    <br />
                    <a href="/auth/sso">SSO</a>
                </form>
            )}

            {isDevEnv() && (
                <div>
                    <MockUserForm username="mock-user@rocket" />
                    <MockUserForm username="edit-boc-test@coxautoinc.com" />
                    <MockUserForm username="edit-pt-test@coxautoinc.com" />
                    <MockUserForm username="edit-ap-test@coxautoinc.com" />
                    <MockUserForm username="location.tester@cox.com" />
                </div>
            )}

            {authService.hasStateToken() && authService.factorRequired() && (
                <form method="POST" action="/auth/validate/factor">
                    <PageHeader titleValue={'Two Factor Authentication'} />

                    {factorAnswerFailed && (
                        <Alert title="Failed 2FA" type="error">
                            <span>Failed to generate factor answer token</span>
                        </Alert>
                    )}

                    <SelectInput
                        value={selectedFactor}
                        label="Select input example"
                        name="factorId"
                        onChange={onFactorChange}
                        options={authService.factors()}
                    />
                    <input
                        type="hidden"
                        name="factorId"
                        value={selectedFactor}
                    />

                    {!pushFactorSelected && (
                        <>
                            <TextInput
                                data-testid={'factorAnswer'}
                                name="factorAnswer"
                                placeholder="Token"
                                ref={factorAnswerTextRef}
                                value={factorAnswer}
                                onChange={(e) => {
                                    setFactorAnswer(e.target.value)
                                }}
                            />
                            <input
                                type="hidden"
                                name="factorAnswer"
                                value={factorAnswer}
                            />
                            <Button
                                disabled={factorAnswerGenerating}
                                variant="primary"
                                type="submit">
                                Validate
                            </Button>
                        </>
                    )}
                    {!!pushFactorSelected && (
                        <>
                            <Button
                                disabled={pushSent || pushErrorStatus}
                                onClick={onPushSend}
                                variant="info"
                                type="submit">
                                {pushSent || pushErrorStatus
                                    ? 'Push Sent'
                                    : 'Send Push'}
                            </Button>
                        </>
                    )}
                    {pushErrorStatus && (
                        <Alert type="error" variant="danger">
                            Failed to validated push factor - {pushErrorStatus}
                        </Alert>
                    )}
                </form>
            )}
        </Container>
    )
}
