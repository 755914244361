import { Routes, Route } from 'react-router-dom'
import { SaleTypeMappingsView } from '../views/SaleTypeMappingsView'
import { UserPermissionsView } from '../views/UserPermissionsView'
import { EditUserPermissionsView } from '../views/EditUserPermissionsView'
import { SaleTypesView } from '../views/SaleTypesView'
import { AuctionConfigurationsView } from '../views/AuctionConfigurationsView'

function ConfigRoute() {
    return (
        <Routes>
            <Route
                exact
                path="sale-type-mappings"
                element={<SaleTypeMappingsView />}
            />
            <Route exact path="sale-types" element={<SaleTypesView />} />
            <Route
                exact
                path="user-permissions"
                element={<UserPermissionsView />}
            />
            <Route
                exact
                path="edit-user-permissions/:id"
                element={<EditUserPermissionsView />}
            />
            <Route
                exact
                path="add-user-permissions"
                element={<EditUserPermissionsView />}
            />
            <Route
                exact
                path="auction-configs"
                element={<AuctionConfigurationsView />}
            />
        </Routes>
    )
}

export default ConfigRoute
