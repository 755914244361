import React from 'react'
import { ActionableFeeInfo } from './ActionableFeeInfo'
import { Modal } from '@interstate/components/Modal'

export function DeleteFeeScheduleModal({
    showDeleteFeeModal,
    setShowDeleteFeeModal,
    actionableFeeScheduleFlat,
    confirmDeleteFeeSchedule
}) {
    return (
        <Modal
            data-testid="modal_DeleteFeeSchedule"
            size="small"
            show={showDeleteFeeModal}
            header={
                <ActionableFeeInfo
                    feeScheduleFlat={actionableFeeScheduleFlat}
                    deleteMode={true}></ActionableFeeInfo>
            }
            footer={{
                primary: {
                    label: 'Confirm',
                    action: () => {
                        confirmDeleteFeeSchedule(actionableFeeScheduleFlat)
                    },
                    datatestid: 'button_DeleteFeeSchedule_Confirm'
                },
                options: [
                    {
                        label: 'Cancel',
                        action: () => {
                            setShowDeleteFeeModal(false)
                        },
                        datatestid: 'button_DeleteFeeSchedule_Cancel'
                    }
                ]
            }}
        />
    )
}
