import { Alert } from '@interstate/components/Alert'
import { useRef, useEffect } from 'react'

export function PatSuccessAlert({ success, text, disableFade }) {
    const scrollRef = useRef(null)
    useEffect(() => {
        scrollRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }, [])

    return (
        <>
            {success && (
                <div>
                    <div ref={scrollRef}>&nbsp;</div>
                    <Alert
                        type="success"
                        title="Success"
                        disableSuccessFade={disableFade}>
                        {text || success}
                    </Alert>
                </div>
            )}
        </>
    )
}
