import { Alert } from '@interstate/components/Alert'
import { useRef, useEffect } from 'react'

export function PatWarningAlert({ error, text }) {
    const scrollRef = useRef(null)
    useEffect(() => {
        scrollRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }, [])

    return (
        <>
            {error && (
                <div>
                    <div ref={scrollRef}>&nbsp;</div>
                    <Alert type="warning" title="Warning">
                        {text || error}
                    </Alert>
                </div>
            )}
        </>
    )
}
