import axios from 'axios'
import { useAsyncValue } from '../utils/CustomHooksUtil'

export function useRateCardDistinctDescriptions() {
    return useAsyncValue(() => {
        const endPoint = `/api/rateCard/distinctDescriptions`
        return axios.get(endPoint).then(({ data }) => data)
    }, [])
}

export function useGetAllRateCards() {
    return useAsyncValue(() => {
        const endPoint = `/api/rateCard/all`
        return axios.get(endPoint).then(({ data }) => data)
    }, [])
}

export function useRateCardsByDescription(rateDescription) {
    return useAsyncValue(() => {
        const endPoint = `/api/rateCard/description?rateDescription=${rateDescription}`
        return axios.get(endPoint).then(({ data }) => data)
    }, [rateDescription])
}

export function saveRateCardData(rateCardInfo) {
    const endPoint = `/api/rateCard/save`
    return axios.post(endPoint, rateCardInfo).then(({ data }) => data)
    // return useAsyncValue(() => {
    //     const endPoint = `/api/rateCard/save`
    //     return axios.post(endPoint, rateCardInfo).then(({data}) => data);
    // }, [rateCardInfo])
}
