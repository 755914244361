import { Typography } from '@interstate/components/Typography'
import { Box } from '@interstate/components/Box'

export function PageHeader({ titleValue }) {
    return (
        <Box marginBottom="10px">
            <Typography tag="span" variant="h2">
                {titleValue}
            </Typography>
        </Box>
    )
}
