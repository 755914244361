import { EllipsisHorizontalIcon } from '@interstate/components/Icons'
import { Button } from '@interstate/components/Button'
import { React } from 'react'
import { Menu, useMenu } from '@interstate/components/Menu'
import { useAuth } from '../auth/AuthProvider'

export function ThreeDotAction({
    onEdit,
    onDelete,
    item,
    roles = [],
    inLineSave = false,
    onSave,
    onCancel,
    locationId
}) {
    const authService = useAuth()

    const isDisabled = () => {
        const role = authService?.principal?.role
        const roleLocationId =
            authService?.principal?.locationId?.split(',') || []
        if (roles.includes(role)) {
            if (['AUCTION_EDIT'].includes(role)) {
                if (
                    locationId &&
                    roleLocationId &&
                    !roleLocationId.includes(locationId)
                ) {
                    return true
                }
            }
            return false
        }
        return true
    }

    const menuProps = useMenu()
    const onSelect = (e) => {
        switch (e.target.value) {
            case 'cancel':
                onCancel(item)
                break
            case 'save':
                onSave(item)
                break
            case 'edit':
                onEdit(item)
                break
            case 'delete':
                onDelete(item)
                break
            default:
                break
        }
    }

    let menuOptions = []
    if (onEdit && !inLineSave) {
        menuOptions.push({
            label: 'Edit',
            'data-testid': `edit-${item?.id}`,
            value: 'edit',
            disabled: isDisabled()
        })
    }

    if (onDelete) {
        menuOptions.push({
            label: 'Delete',
            'data-testid': `delete-${item?.id}`,
            value: 'delete',
            disabled: isDisabled()
        })
    }

    if (inLineSave) {
        menuOptions.push({
            label: 'Save',
            'data-testid': `save-${item?.id}`,
            value: 'save',
            disabled: isDisabled()
        })
        menuOptions.push({
            label: 'Cancel',
            'data-testid': `cancel-${item?.id}`,
            value: 'cancel'
        })
    }

    return (
        <>
            <Button
                data-testid={`action-${item?.id}`}
                onClick={menuProps.onClick}
                icon={<EllipsisHorizontalIcon />}
                buttonStyle={'tertiary'}
            />
            <Menu
                referenceProps={menuProps.referenceProps}
                onSelect={onSelect}
                menuOptions={menuOptions}
            />
        </>
    )
}
