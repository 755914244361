import React from 'react'
import { Popover } from '@interstate/components/Popover'
import {
    isDateAfterToday,
    isDateBeforeToday,
    isDateEqualToday
} from '../../utils/date.helper.util'
import { Button } from '@interstate/components/Button'
import { FEE_SCHEDULE_TYPE, PAT_ROLE } from '../../resources/enum.constants'

export function SearchResultsActionPopover({
    feeScheduleFlat,
    showExpireFeeSchedule,
    showDeleteFeeSchedule,
    copyFeeSchedule,
    editFeeSchedule,
    userRole
}) {
    function isEditOptionAvailable() {
        if (userRole === PAT_ROLE.ADMIN || userRole === PAT_ROLE.BOC_EDIT) {
            return true
        } else if (userRole === PAT_ROLE.AUCTION_EDIT) {
            if (
                feeScheduleFlat.contractType === FEE_SCHEDULE_TYPE.LOCAL_DEALS
            ) {
                return true
            }
        } else if (userRole === PAT_ROLE.PRICING_EDIT) {
            if (feeScheduleFlat.contractType === FEE_SCHEDULE_TYPE.DEFAULTS) {
                return true
            }
        }
        return false
    }

    function isExpireOptionAvailable() {
        if (
            isEditOptionAvailable() &&
            isDateBeforeToday(feeScheduleFlat.startDate, 'YYYY-MM-DD') &&
            (feeScheduleFlat.endDate === null ||
                isDateAfterToday(feeScheduleFlat.endDate, 'YYYY-MM-DD'))
        ) {
            return true
        }
        return false
    }

    return (
        <Popover
            onOpen={function Ua() {}}
            popoverContent={
                <>
                    {isEditOptionAvailable() && (
                        <div>
                            <button
                                onClick={() =>
                                    showDeleteFeeSchedule(feeScheduleFlat)
                                }>
                                Delete
                            </button>
                        </div>
                    )}
                    {isExpireOptionAvailable() && (
                        <>
                            <div>&nbsp;</div>
                            <div>
                                <button
                                    onClick={() =>
                                        showExpireFeeSchedule(feeScheduleFlat)
                                    }>
                                    Expire
                                </button>
                            </div>
                        </>
                    )}
                    {isEditOptionAvailable() && (
                        <>
                            <div>&nbsp;</div>
                            <div>
                                <button
                                    onClick={() => {
                                        editFeeSchedule(feeScheduleFlat)
                                    }}>
                                    Edit
                                </button>
                            </div>
                        </>
                    )}

                    <div>&nbsp;</div>
                    <div>
                        <button
                            onClick={() => copyFeeSchedule(feeScheduleFlat)}>
                            Copy
                        </button>
                    </div>
                </>
            }
            trigger="hover">
            <Button buttonStyle="primary" onClick={function Ua() {}}>
                Action
            </Button>
        </Popover>
    )
}
