export function getEnvironment() {
    console.log('NODE_ENV:', process.env.NODE_ENV)
    console.log('BUILD_ENV:', process.env.BUILD_ENV)
    return process.env.NODE_ENV
}

export function isProdEnv() {
    return process.env.NODE_ENV === 'prod'
}

export function isDevEnv() {
    const uri = window.location.host
    return ['localhost', 'int5'].some((search) => uri.includes(search))
}
