import axios from 'axios'
import { useAsyncValue } from '../utils/CustomHooksUtil'

export function useGetManualFees(bucode, sblu) {
    return useAsyncValue(() => {
        if (!bucode || !sblu) {
            return Promise.resolve(null)
        }
        return axios
            .get(`/api/manual/bucode/${bucode}/sblu/${sblu}`)
            .then(({ data }) => data)
    }, [bucode, sblu])
}
