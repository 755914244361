import React, { useEffect, useState, useMemo } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { deleteStorage, getStorage, setStorage } from '../utils/storageService'
import {
    FEE_SCHEDULE_STORAGE_KEY,
    PUBLISHED_FEE_SCHEDULE_STORAGE_KEY
} from '../utils/constants'
import {
    publishFeeSchedule,
    usePreviewFeeChanges
} from '../service/feeScheduleService'
import {
    PrimaryActionButton,
    SecondaryActionButton
} from '../components/InputControls'
import { PatErrorAlert } from '../components/common/PatErrorAlert'
import { PageHeader } from '../components/PageHeader'
import { Container } from '../components/Container'
import { ReviewFeesPageTable } from '../components/review/ReviewFeesPageTable'
import { ReviewFeesPageNewFeesTable } from '../components/review/ReviewFeesPageNewFeesTable'
import { getFeeScheduleFlatForCSV } from '../utils/fee.schedule.helper'
import { getTodayDateInFormat } from '../utils/date.helper.util'
import { CsvExport } from '../components/search/CsvExport'
import { isEmptyStringArray } from '../utils/commonValidations'

export function ReviewFeesView(props) {
    const navigate = useNavigate()
    // holds value of feeSchedule from local storage
    const [draftFeeSchedule, setDraftFeeSchedule] = useState()
    // convert all overrides and excludes into individual saleTypeFee objects
    const [saleTypeFeeTableData, setSaleTypeFeeTableData] = useState([])
    // holds value of all overlapping feeScheduleFlat list from database
    const [conflictingFeeScheduleFlatList, setConflictingFeeScheduleFlatList] =
        useState([])
    // holds value of all feeSchedule that was in the database that the user needs to be aware of when creating a new feeSchedule
    const [awarenessFeeScheduleFlatList, setAwarenessFeeScheduleFlatList] =
        useState([])
    // holds value of all feeSchedule that was in the database, and are part of excluded locations
    const [
        excludedAwarenessFeeScheduleFlatList,
        setExcludedAwarenessFeeScheduleFlatList
    ] = useState([])
    // holds value of publish error
    const [publishError, setPublishError] = useState()

    useEffect(() => {
        console.log('Parsing feeSchedule from Local Storage...')
        const feeScheduleFromLocalStorate = JSON.parse(
            getStorage(FEE_SCHEDULE_STORAGE_KEY)
        )
        console.log(feeScheduleFromLocalStorate)
        setDraftFeeSchedule(feeScheduleFromLocalStorate)
    }, [])

    /**
     *
     * @param accountNumbers
     * @param groupCodes
     * @param altGroupCodes
     * @returns {string}
     */
    function joinAllCustomers(accountNumbers, groupCodes, altGroupCodes) {
        let newCustomersList = []
        if (!isEmptyStringArray(accountNumbers)) {
            newCustomersList = newCustomersList.concat(accountNumbers)
        }
        if (!isEmptyStringArray(groupCodes)) {
            newCustomersList = newCustomersList.concat(groupCodes)
        }
        if (!isEmptyStringArray(altGroupCodes)) {
            newCustomersList = newCustomersList.concat(altGroupCodes)
        }
        return newCustomersList.join(', ')
    }

    useEffect(() => {
        /* loop thru the saleTypeFee list and convert all the overrides into separate saleTypeFee objects
         * */
        if (draftFeeSchedule?.saleTypeFeeList) {
            let denormalizedSaleTypeFeeList = []
            draftFeeSchedule?.saleTypeFeeList.forEach((saleTypeFee) => {
                let saleTypeFeeString = JSON.stringify(saleTypeFee)
                let saleTypeFeeJson = JSON.parse(saleTypeFeeString)
                let overridesJson = null
                if (saleTypeFeeJson.overrides) {
                    let overridesString = JSON.stringify(
                        saleTypeFeeJson.overrides
                    )
                    overridesJson = JSON.parse(overridesString)
                }

                delete saleTypeFeeJson.overrides
                let allCustomers = joinAllCustomers(
                    draftFeeSchedule.accountNumbers,
                    draftFeeSchedule.groupCodes,
                    draftFeeSchedule.altGroupCodes
                )
                saleTypeFeeJson.contractType = draftFeeSchedule.feeScheduleType
                saleTypeFeeJson.startDate = draftFeeSchedule.startDate
                saleTypeFeeJson.endDate = draftFeeSchedule.endDate
                saleTypeFeeJson.customer = allCustomers
                denormalizedSaleTypeFeeList.push(saleTypeFeeJson)
                if (overridesJson && overridesJson.length > 0) {
                    overridesJson.forEach((override) => {
                        override.contractType = draftFeeSchedule.feeScheduleType
                        override.feeCode = saleTypeFeeJson.feeCode
                        override.saleType = saleTypeFeeJson.saleType
                        override.startDate = draftFeeSchedule.startDate
                        override.endDate = draftFeeSchedule.endDate
                        override.customer = allCustomers
                        denormalizedSaleTypeFeeList.push(override)
                    })
                }
            })

            setSaleTypeFeeTableData(denormalizedSaleTypeFeeList)
        }
    }, [draftFeeSchedule])

    const {
        value: feeSchedulePreview,
        loading: feeSchedulePreviewLoading,
        error: feeSchedulePreviewError
    } = usePreviewFeeChanges(draftFeeSchedule)

    useEffect(() => {
        if (feeSchedulePreview) {
            setConflictingFeeScheduleFlatList(
                feeSchedulePreview.conflictingFeeScheduleFlatList
            )
            setAwarenessFeeScheduleFlatList(
                feeSchedulePreview.awarenessFeeScheduleFlatList
            )
            setExcludedAwarenessFeeScheduleFlatList(
                feeSchedulePreview.excludedAwarenessFeeScheduleFlatList
            )
        }
    }, [feeSchedulePreview])

    let conflictingFeeScheduleFlatCsv = useMemo(() => {
        return getFeeScheduleFlatForCSV(conflictingFeeScheduleFlatList)
    }, [conflictingFeeScheduleFlatList])

    // Handle change event for checkboxes
    const handleCheckboxChange = (event, clickedFeeScheduleFlat, tableType) => {
        const { checkboxValue, checked } = event.target.value

        if (tableType === 'overlapFee') {
            conflictingFeeScheduleFlatList.forEach((feeScheduleFlat) => {
                if (feeScheduleFlat.id === clickedFeeScheduleFlat.id) {
                    feeScheduleFlat.expireFee = checked
                }
            })
            setConflictingFeeScheduleFlatList([
                ...conflictingFeeScheduleFlatList
            ])
        } else if (tableType === 'awarenessFee') {
            awarenessFeeScheduleFlatList.forEach((feeScheduleFlat) => {
                if (feeScheduleFlat.id === clickedFeeScheduleFlat.id) {
                    feeScheduleFlat.expireFee = checked
                }
            })
            setAwarenessFeeScheduleFlatList([...awarenessFeeScheduleFlatList])
        } else if (tableType === 'excludedAwarenessFee') {
            excludedAwarenessFeeScheduleFlatList.forEach((feeScheduleFlat) => {
                if (feeScheduleFlat.id === clickedFeeScheduleFlat.id) {
                    feeScheduleFlat.expireFee = checked
                }
            })
            setExcludedAwarenessFeeScheduleFlatList([
                ...excludedAwarenessFeeScheduleFlatList
            ])
        }
    }

    function addToExpireList(feeScheduleFlatList, expireFeeScheduleFlatList) {
        if (feeScheduleFlatList?.length > 0) {
            feeScheduleFlatList.forEach((feeScheduleFlat) => {
                if (
                    feeScheduleFlat.editableByUser &&
                    feeScheduleFlat.expireFee
                ) {
                    expireFeeScheduleFlatList.push(feeScheduleFlat)
                }
            })
        }
    }

    /**
     * Save published fee schedule info to local storage
     * @param publishResult
     */
    function savePublishedInfoToLocalStorage(publishResult) {
        if (
            publishResult &&
            publishResult.publishedFeeScheduleList &&
            publishResult.publishedFeeScheduleList.length > 0
        ) {
            let publishedInfo = {}
            const feeScheduleGroup =
                publishResult.publishedFeeScheduleList[0].feeScheduleGroup
            publishedInfo.feeScheduleGroup = feeScheduleGroup

            // get all fees that were expired during publish
            if (publishResult.feeScheduleExpiredDuringPublish?.length > 0) {
                let expiredFees =
                    publishResult.feeScheduleExpiredDuringPublish.map(
                        (feeScheduleFlat) => feeScheduleFlat.id
                    )
                publishedInfo.feeScheduleExpiredDuringPublish = expiredFees
            }

            // get all fees that were soft deleted during publish
            if (publishResult.feeScheduleDeletedDuringPublish?.length > 0) {
                let deletedFees =
                    publishResult.feeScheduleDeletedDuringPublish.map(
                        (feeScheduleFlat) => feeScheduleFlat.id
                    )
                publishedInfo.feeScheduleDeletedDuringPublish = deletedFees
            }

            setStorage(
                PUBLISHED_FEE_SCHEDULE_STORAGE_KEY,
                JSON.stringify(publishedInfo)
            )
        }
    }

    function publishFees() {
        // if(!feeChanges){
        //     return;
        // }
        // const currentLocations = new Set(feeChanges.expiringLaneFees.map(lf => lf.businessUnit));
        // const newLocations = new Set(feeChanges.newLaneFees.map(lf => lf.businessUnit));
        // const allAffectedLocations = [...new Set([...currentLocations, ...newLocations])];
        //
        // //todo: i THINK this works...
        // const feesCheckVersion = feeChanges.expiringLaneFees.map(lf => lf.createdDateTime).reduce((previous, current) => previous > current ? previous : current)
        //

        setPublishError(null)
        //send the complete FeeScheduleFlat object that needs to be expired/deleted
        let expireFeeScheduleFlatList = []
        addToExpireList(
            conflictingFeeScheduleFlatList,
            expireFeeScheduleFlatList
        )
        addToExpireList(awarenessFeeScheduleFlatList, expireFeeScheduleFlatList)
        addToExpireList(
            excludedAwarenessFeeScheduleFlatList,
            expireFeeScheduleFlatList
        )

        let expireFeeScheduleIdList = expireFeeScheduleFlatList.map(
            (feeScheduleFlat) => feeScheduleFlat.id
        )

        publishFeeSchedule({
            feeSchedule: feeSchedulePreview.feeSchedule,
            expireFeeScheduleIdList: expireFeeScheduleIdList
        }).then(
            (publishResult) => {
                // setPublishResults(publishResult);
                // if(publishResult){
                //     setShowPublishResults(true);
                // }

                if (publishResult?.publishedFeeScheduleList?.length > 0) {
                    const queryParams = {
                        fsg: publishResult.publishedFeeScheduleList[0]
                            .feeScheduleGroup
                    }

                    deleteStorage(FEE_SCHEDULE_STORAGE_KEY)
                    savePublishedInfoToLocalStorage(publishResult)
                    navigate({
                        pathname: '/boc/feeScheduleConfirmation',
                        search: `?${createSearchParams(queryParams)}`
                    })
                }
            },
            (error) => {
                console.log('error', error)
                setPublishError(error.message)
            }
        )
    }

    function backToAddExhibit() {
        navigate('/boc/addFeeSchedule')
    }

    function getCsvFileName() {
        return `Overlapping_Fee_Schedules_${getTodayDateInFormat('MM_DD_YYYY')}.csv`
    }

    return (
        <div>
            <Container>
                <PageHeader titleValue="Review Fee Schedule" />

                {!feeSchedulePreviewLoading && !feeSchedulePreviewError && (
                    <>
                        <div>
                            {draftFeeSchedule && (
                                <div>
                                    {/*Table to display new feeSchedule that is entered by user*/}
                                    <div>&nbsp;</div>
                                    <div>New Fee Schedule(s)</div>

                                    {publishError && (
                                        <PatErrorAlert
                                            error={publishError}
                                            text={publishError}
                                        />
                                    )}
                                    {saleTypeFeeTableData &&
                                        saleTypeFeeTableData.length > 0 && (
                                            <ReviewFeesPageNewFeesTable
                                                tableData={saleTypeFeeTableData}
                                            />
                                        )}
                                </div>
                            )}
                        </div>

                        {/*Table to display overlapping fees that need to be expired*/}
                        <div>&nbsp;</div>
                        <div>Overlapping Fee Schedule(s)</div>
                        <CsvExport
                            csvData={conflictingFeeScheduleFlatCsv}
                            fileName={getCsvFileName()}
                        />
                        <ReviewFeesPageTable
                            tableData={conflictingFeeScheduleFlatList}
                            handleCheckboxChange={handleCheckboxChange}
                            tableType={'overlapFee'}
                        />
                        {/*Table for all fees that user needs to be aware of*/}
                        <div>&nbsp;</div>
                        <div>Fee Schedule(s) For Review</div>

                        <ReviewFeesPageTable
                            tableData={awarenessFeeScheduleFlatList}
                            handleCheckboxChange={handleCheckboxChange}
                            tableType={'awarenessFee'}
                        />
                        {/*Table for all fees of excluded locations that user needs to be aware of*/}
                        <div>&nbsp;</div>
                        <div>Fee Schedule(s) Matched On Excluded Locations</div>

                        <ReviewFeesPageTable
                            tableData={excludedAwarenessFeeScheduleFlatList}
                            handleCheckboxChange={handleCheckboxChange}
                            tableType={'excludedAwarenessFee'}
                        />
                    </>
                )}

                <div>
                    <PrimaryActionButton
                        label="Approve"
                        onClick={publishFees}></PrimaryActionButton>
                    <SecondaryActionButton
                        label="Back to Fee Schedule"
                        onClick={backToAddExhibit}></SecondaryActionButton>
                </div>
            </Container>
        </div>
    )
}
