import { React, useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { debounce } from 'lodash'
import { PageHeader } from '../components/PageHeader'
import { useFeeScheduleById } from '../service/feeScheduleService'
import { useGetAllRateCards } from '../service/rateCardService'
import { getStorage, setStorage } from '../utils/storageService'
import { FEE_SCHEDULE_STORAGE_KEY } from '../utils/constants'
import { FeeScheduleModel } from '../models/fee.schedule.model'
import { SaleTypeFee } from '../components/SaleTypeFee'
import {
    RequiredCustomerIds,
    validateFeeSchedule
} from '../utils/fee.schedule.helper'
import { Container } from '../components/Container'
import { FeeScheduleTypeSelector } from '../components/FeeScheduleTypeSelector'
import { TextArea } from '@interstate/components/TextArea'
import { PatDatePicker } from '../components/common/PatDatePicker'
import { PatErrorAlert } from '../components/common/PatErrorAlert'
import { FeeScheduleTypeWrapper } from '../components/FeeScheduleTypeWrapper'
import { Card } from '@interstate/components/Card'
import { Grid } from '@interstate/components/Grid'
import { Button } from '@interstate/components/Button'
import { Alert } from '@interstate/components/Alert'
import { SaleTypeFeeHeader } from '../components/SaleTypeFeeHeader'
import { useAuth } from '../auth/AuthProvider'
import { FEE_SCHEDULE_TYPE } from '../resources/enum.constants'

const newSaleTypeFee = {
    auctionCodeList: ['ALL'],
    saleType: null,
    feeCode: null,
    rateCardId: null,
    rateCardType: null,
    rateCardDesc: null,
    feeStructure: null,
    excludedAuctionList: null
}

export function EditFeeScheduleView(props) {
    const navigate = useNavigate()
    const authService = useAuth()

    let [searchParams, setSearchParams] = useSearchParams()
    const feeScheduleIdFromQueryString = searchParams.get('feeScheduleId')

    //local state
    const [accounts, setAccounts] = useState([])
    const [groupCodes, setGroupCodes] = useState([])
    const [altGroupCodes, setAltGroupCodes] = useState([])
    const [customerIdError, setCustomerIdError] = useState()
    const [customerIds, setCustomerIds] = useState([])
    const [description, setDescription] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [errorMessages, setErrorMessages] = useState()
    const [saleTypeFeeList, setSaleTypeFeeList] = useState([newSaleTypeFee])
    //the presence or absence of an id denotes whether or not the fee schedule exists in the backend
    const [feeScheduleId, setFeeScheduleId] = useState(null)
    const [feeScheduleType, setFeeScheduleType] = useState()

    const {
        value: allRateCardList,
        loading: allRateCardListLoading,
        error: allRateCardListError
    } = useGetAllRateCards()

    const {
        value: feeScheduleDetails,
        loading: feeScheduleDetailsLoading,
        error: feeScheduleDetailsError
    } = useFeeScheduleById(feeScheduleIdFromQueryString)

    function loadFeeScheduleFromSession() {
        return getStorage(FEE_SCHEDULE_STORAGE_KEY)
    }

    //todo: pros/cons of moving to a single state object?
    function setFeeSchedule(feeSchedule) {
        // setCustomerIds(feeSchedule.customerIds);
        setAccounts(
            feeSchedule.accountNumbers?.length > 0
                ? feeSchedule.accountNumbers
                : []
        )
        setGroupCodes(
            feeSchedule.groupCodes?.length > 0 ? feeSchedule.groupCodes : []
        )
        setAltGroupCodes(
            feeSchedule.altGroupCodes?.length > 0
                ? feeSchedule.altGroupCodes
                : []
        )
        setDescription(feeSchedule.description)
        setStartDate(feeSchedule.startDate)
        setEndDate(feeSchedule.endDate)
        setSaleTypeFeeList(feeSchedule.saleTypeFeeList)
        if (feeSchedule.id) {
            setFeeScheduleId(feeSchedule.id)
        }
        setFeeScheduleType(feeSchedule.feeScheduleType)
    }

    useEffect(() => {
        if (allRateCardList && !feeScheduleDetailsLoading) {
            if (feeScheduleDetails) {
                setFeeSchedule(feeScheduleDetails)
            } else {
                const feeScheduleFromSession = JSON.parse(
                    loadFeeScheduleFromSession()
                )
                if (feeScheduleFromSession) {
                    setFeeSchedule(feeScheduleFromSession)
                }
            }
        }
    }, [allRateCardList, feeScheduleDetails])

    // function clearAccounts(feeScheduleType) {
    //   if (feeScheduleType === FEE_SCHEDULE_TYPE.GLOBAL_OVERRIDES || feeScheduleType === FEE_SCHEDULE_TYPE.DEFAULTS) {
    //     setAccounts([]);
    //     setGroupCodes([]);
    //     setAltGroupCodes([]);
    //     // setCustomerIds([]);
    //   }
    //
    // }

    function handleInputChange(event) {
        switch (event.target.name) {
            case 'feeScheduleType':
                setFeeScheduleType(event.target.value)
                break
            case 'accounts':
                setAccounts(event.target.value.replace(' ', '').split(','))
                break
            case 'groupCodes':
                setGroupCodes(event.target.value.replace(' ', '').split(','))
                break
            case 'altGroupCodes':
                setAltGroupCodes(event.target.value.replace(' ', '').split(','))
                break
            case 'description':
                setDescription(event.target.value)
                break
            case 'startDate':
                setStartDate(event.target.value?.additionalFormats?.iso)
                break
            case 'endDate':
                setEndDate(event.target.value?.additionalFormats?.iso)
                break
            default:
                break
        }
    }

    function handleAccountBlur() {
        if (
            accounts?.length >= 0 ||
            groupCodes?.length >= 0 ||
            altGroupCodes?.length >= 0
        ) {
            // setCustomerIds([...accounts, ...groupCodes, ...altGroupCodes]);
        }
    }

    function createNewSaleTypeFee() {
        return newSaleTypeFee
    }

    function addNewSaleTypeFee() {
        const newSaleTypeFee = createNewSaleTypeFee()
        const newSaleTypeFeeList = [...saleTypeFeeList]
        newSaleTypeFeeList.push(newSaleTypeFee)
        setSaleTypeFeeList(newSaleTypeFeeList)
    }

    function reviewFees() {
        setErrorMessages()
        const feeScheduleModel = new FeeScheduleModel()
        feeScheduleModel.setFeeScheduleType(feeScheduleType)
        if (
            feeScheduleType !== FEE_SCHEDULE_TYPE.GLOBAL_OVERRIDES &&
            feeScheduleType !== FEE_SCHEDULE_TYPE.DEFAULTS
        ) {
            feeScheduleModel.setAccountNumbers(accounts)
            feeScheduleModel.setGroupCodes(groupCodes)
            feeScheduleModel.setAltGroupCodes(altGroupCodes)
        }

        feeScheduleModel.setDescription(description)
        feeScheduleModel.setStartDate(startDate)
        feeScheduleModel.setEndDate(endDate)
        feeScheduleModel.setSaleTypeFeeList(saleTypeFeeList)

        let newErrorMessages = validateFeeSchedule(feeScheduleModel)
        if (Object.keys(newErrorMessages).length > 0) {
            // console.log('newErrorMessages', newErrorMessages)
            setErrorMessages(newErrorMessages)
            return false
        }

        setStorage(
            FEE_SCHEDULE_STORAGE_KEY,
            JSON.stringify(feeScheduleModel.getObject())
        )
        navigate('/boc/reviewFees')
    }

    function updateSaleTypeFee(index, data) {
        const newList = [...saleTypeFeeList]
        if (data) {
            newList[index] = data
        } else {
            newList.splice(index, 1)
        }
        setSaleTypeFeeList(newList)
    }

    function endDateSetter() {
        if (startDate) {
            let value = new Date(startDate?.additionalFormats?.iso || startDate)
            value.setDate(value.getDate())
            return value
        }
        return null
    }

    const qtySaleTypeFeeList = saleTypeFeeList.length

    return (
        <>
            <Container>
                <PageHeader titleValue="Add New Fee Schedule" />

                <Grid
                    container
                    columnSpacing={'15px'}
                    sx={{ marginBlockEnd: '10px' }}>
                    <Grid
                        item
                        xs={4}
                        data-testid="container_Select_FeeScheduleType">
                        <FeeScheduleTypeSelector
                            value={feeScheduleType}
                            name="feeScheduleType"
                            onChange={handleInputChange}
                            moduleID="FeeScheduleType"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        data-testid="container_DatePicker_StartDate">
                        <div>
                            <PatDatePicker
                                name="startDate"
                                minToday
                                label="Start Date"
                                data-testid="datePicker_StartDate"
                                value={new Date(startDate)}
                                required
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            {errorMessages?.startDate && (
                                <Alert
                                    onCloseClick={function Ua() {}}
                                    title="Error"
                                    type="error"
                                    sx={{ marginBlockEnd: '10px' }}>
                                    <span>{errorMessages.startDate}</span>
                                </Alert>
                            )}
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        data-testid="container_DatePicker_EndDate">
                        <PatDatePicker
                            name="endDate"
                            label="End Date"
                            data-testid="datePicker_EndDate"
                            value={new Date(endDate)}
                            minDate={endDateSetter()}
                            maxDays={
                                authService.principal?.role === 'AUCTION_EDIT'
                                    ? 14
                                    : null
                            }
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        data-testid="container_TextArea_Description">
                        {/* For now, we're limiting description to 30 chars, because that's the AS400 limit. The new backend allows 128. */}
                        <TextArea
                            name="description"
                            label="Description"
                            data-testid="textarea_Description"
                            rows="3"
                            cols="100"
                            onChange={debounce(handleInputChange, 500)}
                            value={description}
                        />
                        <div>
                            {errorMessages?.description && (
                                <Alert
                                    onCloseClick={function Ua() {}}
                                    title="Error"
                                    type="error"
                                    sx={{ marginBlockEnd: '10px' }}>
                                    <span>{errorMessages.description}</span>
                                </Alert>
                            )}
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    columnSpacing={'15px'}
                    sx={{ marginBlockEnd: '10px' }}>
                    <FeeScheduleTypeWrapper
                        type={feeScheduleType}
                        types={RequiredCustomerIds}>
                        <Grid
                            item
                            xs={4}
                            data-testid="container_TextArea_Accounts">
                            <TextArea
                                name="accounts"
                                label="Accounts"
                                data-testid="textarea_Accounts"
                                rows="3"
                                cols="100"
                                onChange={handleInputChange}
                                hasError={customerIdError}
                                errorMessage={customerIdError}
                                onBlur={handleAccountBlur}
                                value={(accounts || []).join(', ')}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            marginBottom={'10px'}
                            data-testid="container_TextArea_GroupCodes">
                            <TextArea
                                name="groupCodes"
                                label="Group Codes"
                                data-testid="textarea_GroupCodes"
                                rows="3"
                                cols="100"
                                onChange={handleInputChange}
                                hasError={customerIdError}
                                errorMessage={customerIdError}
                                onBlur={handleAccountBlur}
                                value={(groupCodes || []).join(', ')}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            marginBottom={'10px'}
                            data-testid="container_TextArea_AlternateGroupCodes">
                            <TextArea
                                name="altGroupCodes"
                                label="Alternate Group Codes"
                                data-testid="textarea_AlternateGroupCodes"
                                rows="3"
                                cols="100"
                                onChange={handleInputChange}
                                hasError={customerIdError}
                                errorMessage={customerIdError}
                                onBlur={handleAccountBlur}
                                value={(altGroupCodes || []).join(', ')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PatErrorAlert error={errorMessages?.customerIds} />
                        </Grid>
                    </FeeScheduleTypeWrapper>
                </Grid>

                {saleTypeFeeList.map((stf, stflIndex) => (
                    <div
                        data-maptype="stflI"
                        data-mapindex={stflIndex}
                        key={stflIndex}>
                        <Card
                            data-testid={
                                'card_SaleTypeFeeList_stflI_' + stflIndex
                            }
                            sx={{
                                marginBottom: '15px',
                                backgroundColor: '#F4F4F4'
                            }}
                            header={
                                <SaleTypeFeeHeader
                                    stflIndex={stflIndex}
                                    onDataChange={(data) =>
                                        updateSaleTypeFee(stflIndex, data)
                                    }
                                    qtySaleTypeFeeList={qtySaleTypeFeeList}
                                />
                            }
                            content={
                                <>
                                    <SaleTypeFee
                                        scheduleFeeType={feeScheduleType}
                                        data={stf}
                                        onDataChange={(data) =>
                                            updateSaleTypeFee(stflIndex, data)
                                        }
                                        allRateCardsList={allRateCardList}
                                        stflIndex={stflIndex}
                                    />
                                    {errorMessages?.saleTypeFeeList && (
                                        <Alert
                                            onCloseClick={function Ua() {}}
                                            title="Error"
                                            type="error"
                                            sx={{ marginBlockEnd: '10px' }}
                                            data-testid={
                                                'alert_ErrorMessages_stflI_' +
                                                stflIndex
                                            }>
                                            <span>
                                                {errorMessages.saleTypeFeeList}
                                            </span>
                                        </Alert>
                                    )}
                                </>
                            }
                        />
                    </div>
                ))}

                <Grid
                    container
                    justifyContent="space-between"
                    marginBottom="15px">
                    <Grid item data-testid="container_Button_AddSaleType">
                        <Button
                            buttonStyle="secondary"
                            name="btnAddAnotherSaleType"
                            data-testid="button_AddSaleType"
                            onClick={addNewSaleTypeFee}>
                            Add Sale Type
                        </Button>
                    </Grid>
                    <Grid item data-testid="container_Button_ReviewFeeSchedule">
                        <Button
                            name="btnReviewFeeSchedule"
                            data-testid="button_ReviewFeeSchedule"
                            onClick={() => {
                                reviewFees()
                            }}>
                            Review Fee Schedule
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
