import moment from 'moment'

export function isDateEqualToday(date, format) {
    if (!date) {
        return false
    }
    let todayDate = moment()
    date = moment(date, format)
    if (date.isSame(todayDate, 'day')) {
        return true
    }
    return false
}
export function isDateAfterToday(date, format) {
    if (!date) {
        return false
    }
    let todayDate = moment()
    date = moment(date, format)
    if (date.isAfter(todayDate, 'day')) {
        return true
    }
    return false
}

export function isDateBeforeToday(date, format) {
    if (!date) {
        return false
    }
    let todayDate = moment()
    date = moment(date, format)
    if (date.isBefore(todayDate, 'day')) {
        return true
    }
    return false
}

export function addDaysToToday(days) {
    let momentDate = moment().add(days, 'days')
    return momentDate.toDate()
}

export function getIsoString(momentDate) {
    return momentDate.toISOString()
}

export function getTodayDate() {
    return new Date()
}

export function getTodayDateInFormat(format) {
    let todayDate = moment()
    return todayDate.format(format)
}

export function convertDateStringToJsDate(date, format) {
    if (!date) {
        return undefined
    }
    let momentDate = moment(date, format)
    return momentDate.toDate()
}
