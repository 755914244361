import axios from 'axios'
import { useAsyncValue } from '../utils/CustomHooksUtil'
import { useState } from 'react'

export function useSaleTypeMappings(locations) {
    const [manualTrigger, setManualTrigger] = useState({})
    const state = useAsyncValue(() => {
        if (!locations?.length) {
            return Promise.resolve(null)
        }
        return axios
            .get(`/api/configs/sale-type-mappings`, {
                params: { location: locations },
                paramsSerializer: { indexes: null }
            })
            .then(({ data }) => {
                return data
            })
    }, [locations, manualTrigger])

    return { ...state, reload: () => setManualTrigger({}) }
}

export function createSaleTypeMapping(create) {
    return axios.post(`/api/configs/sale-type-mappings`, create)
}

export function updateSaleTypeMapping(update) {
    return axios.put(`/api/configs/sale-type-mappings/${update.id}`, update)
}

export function deleteSaleTypeMapping(id) {
    return axios.delete(`/api/configs/sale-type-mappings/${id}`)
}

export function clearServerCache() {
    return axios.get(`/api/configs/clearCache`)
}

export function useGetAllUsers(trigger) {
    return useAsyncValue(() => {
        return axios.get(`/api/configs/users/all`).then((data) => {
            return data.data
        })
    }, [trigger])
}

export function useGetByUserId(userId) {
    return useAsyncValue(() => {
        if (!userId) {
            return Promise.resolve({
                username: '',
                email: '',
                firstName: '',
                lastName: '',
                role: 'VIEW',
                locationId: '',
                enabled: true
            })
        } else {
            return axios
                .get(`/api/configs/users/user/${userId}`)
                .then((data) => {
                    return data.data
                })
        }
    }, [userId])
}

export function useGetByUserName(username) {
    return useAsyncValue(() => {
        return axios
            .get(`/api/configs/users/username/${username}`)
            .then((data) => {
                return data.data
            })
    }, [username])
}

export function createUser(user) {
    return axios.post(`/api/configs/users/user`, user)
}

export function updateUser(userId, user) {
    return axios.put(`/api/configs/users/user/${userId}`, user)
}

export function deleteUser(userId) {
    return axios.delete(`/api/configs/users/user/${userId}`)
}

function getParentName(arr, id) {
    return arr.find((item) => item.id === id)?.name
}

export function useSaleTypes(trigger) {
    return useAsyncValue(() => {
        return axios.get(`/api/processConfigs/saleTypes`).then((data) => {
            return data.data
                .map((item) => {
                    item.key = item.id
                    item.parentName = getParentName(
                        data.data,
                        item['parent_id']
                    )
                    return item
                })
                .sort((a, b) => a.name.localeCompare(b.name))
        })
    }, [trigger])
}

export function useGetUsedSaleTypes(locationId) {
    return useAsyncValue(() => {
        return axios
            .get(`/api/feeSchedules/getUsedSaleTypesByLocation/${locationId}`)
            .then((data) => {
                return data.data
            })
    }, [locationId])
}

export function updateProcessConfig(data, id) {
    return axios.put(`/api/processConfigs/id/${id}`, data)
}

export function createProcessConfig(data) {
    return axios.post(`/api/processConfigs`, data)
}

export function useProcessConfigHistory(id) {
    return useAsyncValue(() => {
        if (id === 'NEW') {
            return Promise.resolve([])
        } else {
            return axios
                .get(`/api/processConfigs/history/id/${id}`)
                .then((data) => {
                    let response = data.data.map((item) => {
                        item.key = item.id
                        return item
                    })
                    return response
                })
        }
    }, [id])
}

export function useAllProcessConfigs(trigger) {
    return useAsyncValue(() => {
        return axios.get(`/api/processConfigs/all`).then((data) => {
            return data.data
        })
    }, [trigger])
}

export function deleteProcessConfig(id) {
    return axios.delete(`/api/processConfigs/id/${id}`)
}

export function updateSaleTypes(data, id) {
    return axios.put(`/api/saletypes/id/${id}`, data)
}

export function createSaleTypes(data) {
    return axios.post(`/api/saletypes`, data)
}

export function deleteSaleTypes(id) {
    return axios.delete(`/api/saletypes/id/${id}`)
}
