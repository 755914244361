import React from 'react'
import { useFeeSchedules } from '../../service/feeScheduleService'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { Table } from '@interstate/components/Table'
import { ThreeDotAction } from '../ThreeDotAction'

function displayFeeSchedule(saleTypeFeeList) {
    return saleTypeFeeList.map((saleTypeFeeList) => {
        return (
            <>
                <div>
                    <span>Auction: </span>
                    <span>
                        <strong>
                            {saleTypeFeeList.auctionCodeList?.join(',')}
                        </strong>
                    </span>
                    &nbsp;&nbsp;
                    <span>Sale type: </span>
                    <span>
                        <strong>{saleTypeFeeList.saleType}</strong>
                    </span>
                    &nbsp;&nbsp;
                    <span>Fee type: </span>
                    <span>
                        <strong>{saleTypeFeeList.feeCode}</strong>
                    </span>
                    &nbsp;
                </div>
            </>
        )
    })
}

function getIsoDateFromString(dateString, startOfDay, endOfDay, dateFormat) {
    if (dateString) {
        if (startOfDay) {
            return moment(`${dateString} 00:00:00`, dateFormat).toISOString(
                true
            )
        } else if (endOfDay) {
            return moment(`${dateString} 23:59:59`, dateFormat).toISOString(
                true
            )
        }
        return moment(`${dateString}`, dateFormat).toISOString(true)
    }
    return null
}

export function FeeScheduleStatusResults({
    customerId,
    startDate,
    endDate,
    feeScheduleStatus
}) {
    let params = {
        customerId,
        startDate: getIsoDateFromString(
            startDate,
            true,
            null,
            'YYYY-MM-DD hh:mm:ss'
        ),
        endDate: getIsoDateFromString(
            endDate,
            null,
            true,
            'YYYY-MM-DD hh:mm:ss'
        ),
        feeScheduleStatus
    }

    const navigate = useNavigate()

    const {
        value: feeScheduleResults,
        loading: feeScheduleResultsLoading,
        error: feeScheduleResultsError
    } = useFeeSchedules(params)

    // console.log('feeScheduleResults', feeScheduleResults)

    function editFeeSchedule(feeScheduleId) {
        // console.log(feeScheduleId)
        navigate(`/boc/addFeeSchedule?feeScheduleId=${feeScheduleId}`)
    }

    return (
        <>
            <br />
            {!feeScheduleResultsLoading &&
                !feeScheduleResultsError &&
                feeScheduleResults?.length > 0 && (
                    <Table
                        tableLayout={'auto'}
                        sortableColumns={true}
                        displayFilter={false}
                        highlightOnHover={true}
                        headerBackgroundColor="dark"
                        columns={[
                            {
                                title: 'Group code / Dealer number',
                                dataIndex: 'customerIds'
                            },
                            {
                                title: 'Start date',
                                dataIndex: 'startDate'
                            },
                            {
                                title: 'End date',
                                dataIndex: 'endDate'
                            },
                            {
                                title: 'Fee schedule',
                                render: (item) => {
                                    console.log(item.saleTypeFeeList)
                                    return displayFeeSchedule(
                                        item.saleTypeFeeList
                                    )
                                }
                            },
                            {
                                title: 'Published date',
                                dataIndex: 'published'
                            },
                            {
                                title: '',
                                render: (item) => (
                                    <ThreeDotAction
                                        item={item}
                                        roles={['ADMIN', 'BOC-EDIT']}
                                        onEdit={(i) => editFeeSchedule(i.id)}
                                    />
                                )
                            }
                        ]}
                        data={feeScheduleResults}
                    />
                )}
        </>
    )
}
