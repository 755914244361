import { React, useRef } from 'react'
import { Button } from '@interstate/components/Button'

export function PrimaryActionButton(props) {
    const origProps = { ...props }
    const addlClasses = props.className ? props.className : ''

    delete origProps.className
    delete origProps.label

    return (
        <div>
            <Button {...origProps} className={`buttonPrimary ${addlClasses}`}>
                {props.label}
            </Button>
        </div>
    )
}

export function SecondaryActionButton(props) {
    const origProps = { ...props }
    const addlClasses = props.className ? props.className : ''

    delete origProps.className
    delete origProps.label

    return (
        <Button {...origProps} className={`buttonSecondary ${addlClasses}`}>
            {props.label}
        </Button>
    )
}

export function SuccessActionButton(props) {
    const origProps = { ...props }
    const addlClasses = props.className ? props.className : ''

    delete origProps.className
    delete origProps.label

    return (
        <Button {...origProps} className={`btn-success ${addlClasses}`}>
            {props.label}
        </Button>
    )
}

export function DisabledActionButton(props) {
    const origProps = { ...props }
    const addlClasses = props.className ? props.className : ''

    delete origProps.className
    delete origProps.label

    return (
        <Button {...origProps} className={`btn-secondary ${addlClasses}`}>
            {props.label}
        </Button>
    )
}

export function SelectInput(props) {
    let baseCss = 'selectInput'
    if (props.overrideBaseCss) {
        baseCss = ''
    }
    const origProps = { ...props }
    const addlClasses = props.className ? props.className : ''
    delete origProps.className
    delete origProps.options
    delete origProps.overrideBaseCss

    return (
        <div>
            <select
                type="select"
                {...origProps}
                value={props.value}
                className={`${baseCss} ${addlClasses}`}>
                {props.options &&
                    props.options.map((option) => {
                        return (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        )
                    })}
            </select>
        </div>
    )
}

export function TextInput(props) {
    const origProps = { ...props }
    const addlClasses = props.className ? props.className : ''
    delete origProps.className
    return (
        <div>
            <input
                type="text"
                className={`textInput ${addlClasses}`}
                {...origProps}></input>
        </div>
    )
}

export function AmountInput(props) {
    const origProps = { ...props }
    const addlClasses = props.className ? props.className : ''
    delete origProps.className
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-currency-dollar"
                viewBox="0 0 16 16">
                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
            </svg>
            <input
                type="text"
                className={`amountInput ${addlClasses}`}
                {...origProps}></input>
        </div>
    )
}

export function TextArea(props) {
    const origProps = { ...props }
    const addlClasses = props.className ? props.className : ''
    delete origProps.className
    return (
        <div>
            <textarea
                className={`textArea ${addlClasses}`}
                {...origProps}></textarea>
        </div>
    )
}

export function DateTimeInput(props) {
    const origProps = { ...props }
    const addlClasses = props.className ? props.className : ''
    delete origProps.className
    const inputReference = useRef(props.name)

    return (
        <div>
            <input
                ref={inputReference}
                type="datetime-local"
                onFocus={() => {
                    inputReference.current.showPicker()
                }}
                className={`textInput ${addlClasses}`}
                {...origProps}></input>
        </div>
    )
}

export function DateInput(props) {
    const origProps = { ...props }
    const addlClasses = props.className ? props.className : ''
    delete origProps.className
    const inputReference = useRef(props.name)
    return (
        <div>
            <input
                type="date"
                ref={inputReference}
                onFocus={() => {
                    inputReference.current.showPicker()
                }}
                className={`textInput ${addlClasses}`}
                {...origProps}></input>
        </div>
    )
}

export function CheckBox(props) {
    const origProps = { ...props }
    const addlClasses = props.className ? props.className : ''
    delete origProps.className
    delete origProps.label

    return (
        <div className="checkBoxWrapper">
            <div className="checkBoxCtrl">
                <input
                    type="checkbox"
                    {...origProps}
                    className={`checkBoxInput ${addlClasses}`}></input>
            </div>
            <div className="checkBoxLbl">{props.label}</div>
        </div>
    )
}

export function Label(props) {
    return (
        <div>
            <label className="form-label" {...props} />
        </div>
    )
}
