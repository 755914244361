import React from 'react'
import { auctionDisplayWithCode } from '../../utils/auction.util'
import { DisplayFlatTieredDescription } from '../search/DisplayFlatTieredDescription'
import { DisplayFeeTierAmount } from '../search/DisplayFeeTierAmount'
import { renderDateFromIsoString } from '../../utils/Formatters'
import { CheckBox } from '@interstate/components/CheckBox'
import { Table } from '@interstate/components/Table'
import { useStaticDataContext } from '../../context/StaticDataContext'

export function ReviewFeesPageTable({
    tableData,
    handleCheckboxChange,
    tableType
}) {
    const staticData = useStaticDataContext()
    const TERMINATE_TYPE_DELETE = 'DELETE'
    const TERMINATE_TYPE_EXPIRE = ['EXPIRE', 'EXPIRETODAY']
    return (
        <div className="reviewFees">
            <Table
                columns={[
                    {
                        className: 'column-contractType',
                        dataIndex: 'contractType',
                        render: (item) => <div>{item}</div>,
                        title: 'Fee Schedule Type'
                    },
                    {
                        className: 'column-auctionCode',
                        render: (item) =>
                            auctionDisplayWithCode(
                                item.buCode,
                                staticData.auctionInfoMap
                            ),
                        title: 'Location'
                    },
                    {
                        className: 'column-saleType',
                        dataIndex: 'saleType',
                        title: 'Sale type'
                    },
                    {
                        className: 'column-customer',
                        dataIndex: 'customer',
                        title: 'Customer'
                    },
                    {
                        className: 'column-feeType',
                        dataIndex: 'feeType',
                        title: 'Fee type'
                    },
                    {
                        className: 'column-flatTiered',
                        render: (item) => (
                            <div>
                                <DisplayFlatTieredDescription
                                    feeScheduleFlat={
                                        item
                                    }></DisplayFlatTieredDescription>
                            </div>
                        ),
                        title: 'Flat/Tiered'
                    },
                    {
                        className: 'column-Amount',
                        render: (item) => (
                            <div>
                                <DisplayFeeTierAmount
                                    feeScheduleFlat={
                                        item
                                    }></DisplayFeeTierAmount>
                            </div>
                        ),
                        title: 'Amount/Tiers'
                    },
                    {
                        className: 'column-startDate',
                        dataIndex: 'startDate',
                        render: (item) => (
                            <div>
                                {renderDateFromIsoString(item, 'MM/DD/YYYY')}
                            </div>
                        ),
                        title: 'Start date'
                    },
                    {
                        className: 'column-endDate',
                        dataIndex: 'endDate',
                        render: (item) => (
                            <div>
                                {renderDateFromIsoString(item, 'MM/DD/YYYY')}
                            </div>
                        ),
                        title: 'End date'
                    },
                    {
                        className: 'column-expire',
                        render: (item) => (
                            <div>
                                {item.editableByUser && (
                                    <CheckBox
                                        checked={item.expireFee}
                                        data-testid="checkbox-1-data-testid"
                                        id="expireFeeSchedule"
                                        label=""
                                        name="expireFeeSchedule"
                                        value={item.id}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                item,
                                                tableType
                                            )
                                        }
                                    />
                                )}
                                &nbsp;
                                {item.expireFee &&
                                    item.terminateType ===
                                        TERMINATE_TYPE_DELETE && (
                                        <span>Delete</span>
                                    )}
                                {item.expireFee &&
                                    TERMINATE_TYPE_EXPIRE.indexOf(
                                        item.terminateType
                                    ) > -1 && <span>Expire</span>}
                            </div>
                        ),
                        title: 'Action'
                    }
                ]}
                data={tableData}
                tableLayout={'auto'}
                enablePagination={true}
                id="overlappingFeesTable"
                onChangePage={function Ua() {}}
                onChangePageSize={function Ua() {}}
                onChangeSelectedRows={function Ua() {}}
                onChangeSorter={function Ua() {}}
                onExpandClick={function Ua() {}}
                onRowCallback={function Ua() {}}
                onRowSelect={function Ua() {}}
            />
        </div>
    )
}
