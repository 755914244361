import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Spinner } from '@interstate/components/Spinner'
import { Modal } from '@interstate/components/Modal'

export function GlobalSpinner({ show }) {
    return (
        <Modal
            show={show}
            size="small"
            header="Processing"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                padding: '15px'
            }}>
            <Spinner
                fullscreen={true}
                message="Processing ..."
                sx={{ marginBottom: '20px' }}
            />
        </Modal>
    )
}

export function SpinnerInterceptor() {
    const [show, setShow] = useState(false)

    useEffect(() => {
        let apiCounter = 0

        const requestInterceptor = axios.interceptors.request.use((config) => {
            if (!config.headers.hideSpinner) setShow(++apiCounter > 0)

            return config
        })

        const responseInterceptor = axios.interceptors.response.use(
            (response) => {
                if (!response.config.headers.hideSpinner)
                    setShow(--apiCounter > 0)

                return response
            },
            (error) => {
                if (!error.config.headers.hideSpinner) setShow(--apiCounter > 0)

                return Promise.reject(error)
            }
        )

        return () => {
            axios.interceptors.request.eject(requestInterceptor)
            axios.interceptors.response.eject(responseInterceptor)
        }
    }, [])

    return show && <GlobalSpinner show={show} />
}
