import { Button } from '@interstate/components/Button'
import { Box } from '@interstate/components/Box'
import { PatInputStyle } from './PatInputStyle'

export function SubmitCancelBox({
    onSubmit,
    onCancel,
    submitText = 'Submit',
    cancelText = 'Cancel'
}) {
    return (
        <Box sx={PatInputStyle}>
            <Button
                onClick={onSubmit}
                data-testid={'submit-button'}
                buttonStyle="primary"
                sx={{ marginRight: '10px' }}>
                {submitText}
            </Button>
            <Button
                onClick={onCancel}
                data-testid={'cancel-button'}
                buttonStyle="secondary">
                {cancelText}
            </Button>
        </Box>
    )
}
