import { React } from 'react'
import { RateCardOnTooltip } from './RateCardOnTooltip'
import { InfoIcon } from '../Icons'
import { populateRateTypeOptions } from '../../utils/rate.card.helper'
import { Select } from '@interstate/components/SelectInput'
import { PatTextInput } from '../common/PatTextInput'
import { Button } from '@interstate/components/Button'
import { Grid } from '@interstate/components/Grid'
import { Popover } from '@interstate/components/Popover'

export function RateCardSelector({
    rateCardType = 'FlatFee',
    feeStructure,
    rateCardDesc,
    feeOverrideIndex,
    openRateCardSearch,
    onRateTypeChange,
    onFlatRateChange,
    dataTestId
}) {
    const rateTypeOptions = populateRateTypeOptions()

    let flatFeeAmount = ''
    if (
        rateCardType === 'FlatFee' &&
        feeStructure &&
        feeStructure.feeTiers &&
        feeStructure.feeTiers.length === 1
    ) {
        flatFeeAmount = feeStructure.feeTiers[0].fee
    }

    return (
        <Grid container columnSpacing={'15px'}>
            <Grid item xs={6}>
                <Select
                    label="Fee Structure"
                    value={
                        rateTypeOptions?.find((o) => o.value === rateCardType)
                            ?.value || ''
                    }
                    placeholder="Select"
                    options={rateTypeOptions}
                    onChange={(e) =>
                        onRateTypeChange(e.target.value, feeOverrideIndex)
                    }
                    data-testid={dataTestId}
                    displayDeselectOption={false}
                />
            </Grid>
            <Grid item xs={6}>
                <>
                    {rateCardType === 'FlatFee' && (
                        <PatTextInput
                            label="Flat Fee"
                            name="flatRate"
                            inputPrefix="$"
                            value={flatFeeAmount}
                            onBlur={(e) =>
                                onFlatRateChange(
                                    e.target.value,
                                    feeOverrideIndex
                                )
                            }
                            data-testid={'textinput_FlatFee' + dataTestId}
                        />
                    )}
                    {rateCardType === 'Tiered' && !feeStructure && (
                        <Button
                            sx={{ float: 'right', marginTop: '20px' }}
                            buttonStyle="secondary"
                            onClick={(e) =>
                                openRateCardSearch(feeOverrideIndex)
                            }
                            data-testid={'button_SearchRateCard' + dataTestId}>
                            Search/Create
                        </Button>
                    )}
                    {rateCardType === 'Tiered' && feeStructure && (
                        <>
                            <Popover
                                onOpen={function Ua() {}}
                                popoverContent={
                                    <RateCardOnTooltip
                                        feeStructure={feeStructure}
                                    />
                                }
                                trigger="hover"
                                data-testid={'RateCard' + dataTestId}>
                                <Button
                                    size="small"
                                    endIcon={<InfoIcon />}
                                    buttonStyle="aux"
                                    onClick={function Ua() {}}
                                    data-testid={
                                        'button_ViewRateCard' + dataTestId
                                    }>
                                    {rateCardDesc}
                                </Button>
                            </Popover>

                            <Button
                                buttonStyle="secondary"
                                onClick={(e) =>
                                    openRateCardSearch(feeOverrideIndex)
                                }
                                data-testid={
                                    'button_ChangeRateCard' + dataTestId
                                }>
                                Change
                            </Button>
                        </>
                    )}
                </>
            </Grid>
        </Grid>
    )
}
