import React, { useContext, useMemo } from 'react'
import { useAsyncValue } from '../utils/CustomHooksUtil'
import axios from 'axios'
import { AuthService } from './authService'

const AuthContext = React.createContext({})

export const useAuth = () => {
    return useContext(AuthContext)
}

export function usePrincipal() {
    return useAsyncValue(() => {
        return axios.get('/auth/user').then(({ data }) => data)
    }, [])
}

export function AuthContextLoader({ children }) {
    const { value: principal, loading } = usePrincipal()

    const authService = useMemo(() => {
        return new AuthService(principal)
    }, [principal])

    return (
        !loading && (
            <AuthContext.Provider value={authService}>
                {children}
            </AuthContext.Provider>
        )
    )
}
