import { useAuth } from '../auth/AuthProvider'
import { PatErrorAlert } from './common/PatErrorAlert'

export function RoleWrapper({ roles, children, alert = false, locationId }) {
    const authService = useAuth()
    const role = authService?.principal?.role

    const isDisabled = () => {
        const roleLocationId =
            authService?.principal?.locationId?.split(',') || []
        if (roles.includes(role)) {
            if (['AUCTION_EDIT'].includes(role)) {
                if (
                    locationId &&
                    roleLocationId &&
                    !roleLocationId.includes(locationId)
                ) {
                    return true
                }
            }
            return false
        }
        return true
    }

    const errorText = 'You are not authorized to view this page.'
    return (
        <>
            {!isDisabled() && children}

            {alert && isDisabled() && (
                <div className="unauthorized">
                    <PatErrorAlert error="unauthorized" text={errorText} />
                </div>
            )}
        </>
    )
}
