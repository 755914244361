import { React } from 'react'
import { RateCardDescriptions } from '../components/ratecard/RateCardDescriptions'
import { RateCardsResults } from '../components/ratecard/RateCardResults'
import { useSearchParams } from 'react-router-dom'
import { PageHeader } from '../components/PageHeader'

export function RateCardsResultsView(props) {
    let [searchParams] = useSearchParams()
    const rateDescription = searchParams.get('rateDescription')

    return (
        <div>
            <PageHeader titleValue="Rate Card Details" />
            <div className="d-flex flex-row">
                <div className="me-1">
                    <div className="mt-1">
                        <RateCardDescriptions />
                    </div>

                    <RateCardsResults rateDescription={rateDescription} />
                </div>
            </div>
        </div>
    )
}
