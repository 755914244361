import { PatTextInput } from '../../components/common/PatTextInput'
import { React, useState } from 'react'
import { PatDatePicker } from '../../components/common/PatDatePicker'
import {
    createSaleTypeMapping,
    updateSaleTypeMapping,
    useGetUsedSaleTypes
} from '../../service/configService'
import { SaleTypesComboBox } from '../../components/common/SaleTypesComboBox'
import { SubmitCancelBox } from '../../components/common/SubmitCancelBox'
import { PatErrorAlert } from '../../components/common/PatErrorAlert'
import { useAuth } from '../../auth/AuthProvider'
import { PAT_ROLE } from '../../resources/enum.constants'

export function SaleTypesMappingEditForm({ data, closeEdit, locationId }) {
    const authService = useAuth()
    const [id] = useState(data.id)
    const [lane, setLane] = useState(data?.lane)
    const [feeCategory, setFeeCategory] = useState(data?.feeCategory)
    const [saleType, setSaleType] = useState(data?.saleType)
    const [startTime, setStartTime] = useState(data?.startTime)
    const [endTime, setEndTime] = useState(data?.endTime)
    const [comments, setComments] = useState(data?.comments)
    const [error, setError] = useState(null)

    const {
        value: usedSaleTypes,
        loading: usedSaleTypesLoading,
        error: usedSaleTypesError
    } = useGetUsedSaleTypes(locationId)

    const onSave = () => {
        if (!lane || lane?.trim().length === 0) {
            setError('Lane is required')
            return
        }

        if (!feeCategory || feeCategory?.trim().length === 0) {
            setError('Table # is required')
            return
        }

        if (!saleType || saleType?.trim().length === 0) {
            setError('Sale Type is required')
            return
        }

        if (
            endTime &&
            new Date(endTime) <= new Date(startTime) &&
            new Date(endTime) <= new Date()
        ) {
            setError('End time must be after start time')
            return
        }

        if (authService?.principal?.role === 'AUCTION_EDIT' && lane === '0') {
            setError('Lane 0 can only be set by BOC team.')
            return
        }

        if (
            authService?.principal?.role === 'AUCTION_EDIT' &&
            saleType === 'Default'
        ) {
            setError('Sale Type of Default can only be set by BOC team.')
            return
        }

        let promiseChain
        if (id === 'NEW') {
            promiseChain = createSaleTypeMapping({
                lane,
                locationId,
                feeCategory,
                saleType,
                startTime,
                endTime,
                comments
            })
        } else {
            promiseChain = updateSaleTypeMapping({
                id,
                locationId,
                lane,
                feeCategory,
                saleType,
                startTime,
                endTime,
                comments
            })
        }
        promiseChain
            .then(() => {
                closeEdit()
            })
            .catch((e) => {
                console.log(e)
                setError('Error with API on saletype')
            })
    }

    const edit = data.id !== 'NEW'

    return (
        <>
            <PatErrorAlert error={usedSaleTypesError} />
            {!usedSaleTypesLoading && (
                <>
                    <PatErrorAlert error={error} />
                    <PatTextInput
                        label="Lane #"
                        value={lane}
                        onChange={(e) => {
                            setLane(e.target.value)
                        }}
                        disabled={edit}
                    />
                    <PatTextInput
                        label="Table #"
                        value={feeCategory}
                        onChange={(e) => {
                            setFeeCategory(e.target.value)
                        }}
                        disabled={edit}
                    />
                    <SaleTypesComboBox
                        label="Sale Type"
                        value={saleType}
                        onChange={(e) => {
                            setSaleType(e.target.value)
                        }}
                        filter={
                            authService.principal.role === PAT_ROLE.AUCTION_EDIT
                                ? usedSaleTypes
                                : null
                        }
                        disabled={edit}
                    />
                    <PatDatePicker
                        label="Start Time"
                        minToday
                        value={new Date(startTime)}
                        disabled={edit}
                        onChange={(e) => {
                            setStartTime(e.target.value?.additionalFormats?.iso)
                        }}
                    />
                    <PatDatePicker
                        label="End Time"
                        minToday
                        value={new Date(endTime)}
                        onChange={(e) => {
                            // console.log(e.target.value)
                            setEndTime(e.target.value?.additionalFormats?.iso)
                        }}
                    />
                    <PatTextInput
                        label="Description"
                        value={comments}
                        onChange={(e) => {
                            setComments(e.target.value)
                        }}
                    />
                    <SubmitCancelBox onSubmit={onSave} onCancel={closeEdit} />
                </>
            )}
        </>
    )
}
