import { React } from 'react'
import { SearchFees } from '../components/search/SearchFees'
import { PageHeader } from '../components/PageHeader'
import { Container } from '../components/Container'

export function BocSearchView(props) {
    return (
        <Container>
            <PageHeader titleValue="Search Fee Schedules" />
            <SearchFees />
        </Container>
    )
}
