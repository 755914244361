export function prodToggleValue(data, id) {
    if (data[id] && data[id]?.prodToggle?.length > 0) {
        return data[id]?.prodToggle === 'ON'
    } else {
        return data['ALL']?.prodToggle === 'ON'
    }
}

export function getConfigData(configs, auctionOptions) {
    let configData = {}
    if (auctionOptions && auctionOptions.length > 0) {
        configData['ALL'] = {
            locationId: 'ALL',
            locationName: 'ALL',
            slopeLow: 0,
            slopeLowId: 'ALL',
            slopeHigh: 0,
            slopeHighId: 'ALL',
            prodToggle: false,
            prodToggleId: 'ALL'
        }
        auctionOptions.forEach((option) => {
            configData[option.value] = {
                locationId: option.value,
                locationName: option.text,
                slopeLowId: 'ALL',
                slopeHighId: 'ALL',
                prodToggleId: 'ALL'
            }
        })
        if (configs && configs.length > 0) {
            configs.forEach((config) => {
                if (!configData[config.buCode]) {
                    configData[config.buCode] = {
                        locationId: config.buCode
                    }
                }
                switch (config.configType) {
                    case 'SLOPE_BPRICE':
                        configData[config.buCode].slopeLowId =
                            config.id || 'ALL'
                        configData[config.buCode].slopeLow = config.configValue
                        break
                    case 'SLOPE_EPRICE':
                        configData[config.buCode].slopeHighId =
                            config.id || 'ALL'
                        configData[config.buCode].slopeHigh = config.configValue
                        break
                    case 'CHARGE_UPDATE':
                        configData[config.buCode].prodToggleId =
                            config.id || 'ALL'
                        configData[config.buCode].prodToggle =
                            config.configStatus
                        break
                    default:
                        break
                }
            })
        }
    }
    return configData
}

export function flattenData(configData, selectedLocationIds) {
    let flatData = []
    Object.keys(configData).forEach((row) => {
        if (
            (selectedLocationIds.length > 0 &&
                selectedLocationIds.includes(row)) ||
            selectedLocationIds.length === 0
        ) {
            flatData.push({
                key: configData[row].locationId,
                locationName: configData[row].locationName,
                locationId: configData[row].locationId,
                slope: `$${configData[row].slopeLow || configData['ALL'].slopeLow} - $${configData[row].slopeHigh || configData['ALL'].slopeHigh}`,
                slopeLow:
                    configData[row].slopeLow || configData['ALL'].slopeLow,
                slopeLowId: configData[row].slopeLowId,
                slopeHigh:
                    configData[row].slopeHigh || configData['ALL'].slopeHigh,
                slopeHighId: configData[row].slopeHighId,
                prodToggle: prodToggleValue(configData, row),
                prodToggleId: configData[row].prodToggleId
            })
        }
    })
    return flatData
}
