import React, { useState } from 'react'
import { importAS400FeeScheduleOnToOne } from '../../service/feeScheduleService'
import { Container } from '../../components/Container'
import { PageHeader } from '../../components/PageHeader'
import { Button } from '@interstate/components/Button'
import { LocationComboBox } from '../../components/common/LocationComboBox'
import { PatDatePicker } from '../../components/common/PatDatePicker'

export default function ImportAS400DataOneToOne() {
    const [endDate, setEndDate] = useState()
    const [auctionCode, setAuctionCode] = useState()
    const [message, setMessage] = useState()

    function handleInputChange(event) {
        switch (event.target.name) {
            case 'endDate':
                setEndDate(event.target.value)
                break
            case 'auctionCode':
                setAuctionCode(event.target.value)
                break
            default:
                break
        }
    }

    function handleSubmit(e) {
        e.preventDefault()
        let as400EndDate = endDate.replace(/-/g, '')
        let importResponse = importAS400FeeScheduleOnToOne(
            auctionCode[0].value,
            as400EndDate
        )

        importResponse
            .then((response) => {
                setMessage(response.data)
            })
            .catch((error) => {
                console.error('error', error)
            })
    }

    return (
        <Container>
            <PageHeader titleValue="Import AS400 Data One to One From Auction" />

            <LocationComboBox
                value={auctionCode}
                multiple={false}
                onChange={(e) => setAuctionCode(e.target.value)}
            />

            <PatDatePicker
                label="End date after"
                name="endDate"
                data-testid="endDate"
                value={endDate}
                onChange={handleInputChange}
            />

            {message && (
                <div>
                    <div>
                        <label>{message}</label>
                    </div>
                </div>
            )}

            <Button
                label="Start Import"
                variant="primary"
                sx={{ marginTop: '10px' }}
                type="submit"
                onClick={handleSubmit}>
                Start Import
            </Button>
        </Container>
    )
}
