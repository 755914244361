import { deleteSaleTypes, useSaleTypes } from '../service/configService'
import { React, useState } from 'react'
import { SaleTypesEditForm } from './saleTypes/SaleTypesEditForm'
import { Container } from '../components/Container'
import { PageHeader } from '../components/PageHeader'
import { Alert } from '@interstate/components/Alert'
import { SaleTypesTree } from '../components/SaleTypesTree'

export function SaleTypesView() {
    const [trigger, setTrigger] = useState(0)
    const [editor, setEditor] = useState(null)
    const {
        value: saleTypes,
        loading: saleTypesLoading,
        error: saleTypesError
    } = useSaleTypes(trigger)
    const [deleteError, setDeleteError] = useState(null)

    const doesSaleTypeExists = (val) => {
        let finder = saleTypes.find((saleType) => saleType.configValue === val)
        return finder !== undefined
    }

    const onDelete = (item) => {
        deleteSaleTypes(item.id)
            .then(() => {
                setTrigger(trigger + 1)
                setDeleteError(null)
            })
            .catch((e) => {
                if (e?.response?.data?.error) {
                    setDeleteError(e.response.data.error)
                } else {
                    setDeleteError('Error deleting sale type')
                }
            })
    }

    return (
        <Container>
            <PageHeader titleValue="Sale Types" />
            {!editor && (
                <>
                    {deleteError && (
                        <Alert type="error" title="Error">
                            {deleteError}
                        </Alert>
                    )}
                    {saleTypesError && (
                        <Alert type="error" title="Error">
                            Error loading sale types
                        </Alert>
                    )}

                    {!saleTypesLoading && !saleTypesError && (
                        <SaleTypesTree
                            data={saleTypes}
                            onDeleteClick={onDelete}
                            onEditClick={(saleType) => {
                                setEditor(saleType)
                            }}
                        />
                    )}

                    {/*{(!saleTypesLoading && !saleTypesError) && <SaleTypesTable saleTypes={saleTypes}*/}
                    {/*                                      onDeleteClick={onDelete}*/}
                    {/*                                      onEditClick={(saleType) => {*/}
                    {/*                                          setEditor(saleType);*/}
                    {/*                                      }}/>}*/}
                </>
            )}

            {editor && (
                <SaleTypesEditForm
                    saleTypes={saleTypes}
                    edit={editor}
                    doesSaleTypeExists={doesSaleTypeExists}
                    saveEdit={() => {
                        setTrigger(trigger + 1)
                        setEditor(null)
                    }}
                    cancelEdit={() => {
                        setEditor(null)
                    }}
                />
            )}
        </Container>
    )
}
