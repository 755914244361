import React from 'react'
import { Modal } from '@interstate/components/Modal'
import { PatSuccessAlert } from '../common/PatSuccessAlert'
import { PatErrorAlert } from '../common/PatErrorAlert'

export function EditExpireSuccessFailure({
    showSuccessFailureModal,
    successMode,
    failureMode,
    setShowSuccessFailureModal
}) {
    function closeModal() {
        setShowSuccessFailureModal(false)
    }
    return (
        <Modal
            size="small"
            show={showSuccessFailureModal}
            header={''}
            footer={{
                primary: {
                    label: 'Ok',
                    action: () => {
                        closeModal()
                    },
                    datatestid: 'button_EditExpireSuccessFailure_Confirm'
                }
            }}>
            <div>
                {successMode && (
                    <PatSuccessAlert
                        success={true}
                        text="Fee Schedule updated"
                        disableFade={true}
                    />
                )}
                {failureMode && (
                    <PatErrorAlert
                        error={true}
                        text="Error updating Fee Schedule"
                    />
                )}
            </div>
        </Modal>
    )
}
