const NAMESPACE_NAME = 'PATUI'

export function setStorage(key, value) {
    sessionStorage.setItem(`${NAMESPACE_NAME}-${key}`, value)
}

export function getStorage(key) {
    return sessionStorage.getItem(`${NAMESPACE_NAME}-${key}`)
}

export function deleteStorage(key) {
    return sessionStorage.removeItem(`${NAMESPACE_NAME}-${key}`)
}
