import { Table } from '@interstate/components/Table'
import React, { useEffect } from 'react'
import { getVehicleAuctionNotes } from '../service/auctionVehicleDataService'

export function VehicleNotesTable({ auction, workOrderNumber }) {
    const [vehicleNotes, setVehicleNotes] = React.useState([])

    useEffect(() => {
        getVehicleAuctionNotes(auction, workOrderNumber)
            .then((d) => {
                setVehicleNotes(d)
            })
            .catch((error) => {
                setVehicleNotes(error.response.data)
            })
    }, [])

    return (
        <Table
            tableLayout={'auto'}
            enablePagination={false}
            columns={[
                {
                    title: 'Note',
                    render: (item) => <span>{item.notesText}</span>
                },
                {
                    title: 'Note Type',
                    render: (item) => <span>{item.addedBy}</span>
                },
                {
                    title: 'Note Date',
                    render: (item) => <span>{item.addedDate}</span>
                }
            ]}
            data={vehicleNotes['vehicleNotes_ByWO_R']}
        />
    )
}
