import { React } from 'react'
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable
} from '@tanstack/react-table'

import {
    describeFeeStructureOldFormat,
    mapRateCardToFeeStructure
} from '../../utils/Formatters'
import { RateCardOnTooltip } from './RateCardOnTooltip'
import { InfoIcon } from '../Icons'
import { Popover } from '@interstate/components/Popover'

const columnHelper = createColumnHelper()

const renderFeeStructure = (rateCard) => {
    return (
        <Popover
            onOpen={function Ua() {}}
            popoverContent={
                <RateCardOnTooltip
                    feeStructure={mapRateCardToFeeStructure(rateCard)}
                />
            }
            trigger="hover">
            {describeFeeStructureOldFormat(rateCard)} <InfoIcon />
        </Popover>
    )
}

const columns = [
    columnHelper.accessor((row) => row.description, {
        header: 'Description',
        footer: (info) => info.column.id,
        id: 'description'
    }),
    // columnHelper.accessor((row) => row.type, {
    //     header: 'Type',
    //     cell: (info) => renderFeeStructureType(info.getValue()),
    //     footer: (info) => info.column.id,
    //     id: 'type'
    // }),
    // columnHelper.accessor((row) => row.numTiers, {
    //     header: 'Tiers',
    //     footer: (info) => info.column.id,
    //     id: 'numTiers'
    // }),
    // columnHelper.accessor(
    //     (row) => row.priceTier && row.priceTier.length && row.priceTier[0].fee,
    //     {
    //         header: 'Start Fee',
    //         cell: (info) => dollars.format(info.getValue()),
    //         footer: (info) => info.column.id,
    //         id: 'startFee'
    //     }
    // ),
    columnHelper.accessor((row) => row, {
        header: 'Tiers: Low/High Fee',
        cell: (info) => renderFeeStructure(info.getValue()),
        footer: (info) => info.column.id,
        id: 'feeStructure'
    })
    // columnHelper.accessor(row => row.createdDateTime, {
    //   header: 'Created',
    //   cell: info => renderShortDate(info.getValue()),
    //   footer: info => info.column.id,
    //   id: 'createdDateTime'
    // })
]

export function RateCardsTable({ data, onRowClick }) {
    const onRowClickInternal = (row) => {
        console.log('RateCardsTable onRowClick', row)
        if (!!onRowClick) {
            onRowClick(row)
        }
    }

    // console.log('rateCardsData:', data)
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel()
    })

    return (
        <table
            className="bocTable rateCardsList patTable"
            data-testid="rateCardsTable">
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                      )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr
                        key={row.id}
                        onClick={(evt) => onRowClickInternal(row.original)}>
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
