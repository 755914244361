import { Table } from '@interstate/components/Table'
import React from 'react'

export function CalculatedFeeTable({ data }) {
    return (
        <>
            <h2>Calculated Fees</h2>
            <Table
                tableLayout={'auto'}
                columns={[
                    {
                        title: 'Fee Category',
                        render: (item) => <span>{item.feeCategory}</span>
                    },
                    {
                        title: 'Sale Type',
                        render: (item) => <span>{item.saleType}</span>
                    },
                    {
                        title: 'Fee Schedule Type',
                        render: (item) => <span>{item.contractType}</span>
                    },
                    {
                        title: 'Fee Type',
                        render: (item) => <span>{item.feeType}</span>
                    },
                    {
                        title: 'Pat Fee',
                        render: (item) => <span>{item.patCalculatedFee}</span>
                    },
                    {
                        title: 'AS400 Fee',
                        render: (item) => <span>{item.as400CalculatedFee}</span>
                    },
                    {
                        title: 'FeeID',
                        render: (item) => <span>{item.feeSchedulePartId}</span>
                    }
                ]}
                data={data}
            />
        </>
    )
}
