import { React } from 'react'
import { useRateCardsByDescription } from '../../service/rateCardService'

export function RateCardsResults({ rateDescription }) {
    const {
        value: rateCardList,
        loading: rateCardListLoading,
        error: rateCardListError
    } = useRateCardsByDescription(rateDescription)

    return (
        <div>
            {!rateCardListLoading && !rateCardListError && (
                <table className="bocFeesDetail">
                    <tr>
                        <td>Id</td>
                        <td>Auction</td>
                        <td>Description</td>
                        <td>Moniker</td>
                    </tr>
                    {rateCardList.map((rateCard) => {
                        return (
                            <tr key={rateCard.id}>
                                <td>{rateCard.id}</td>
                                <td>{rateCard.locationId}</td>
                                <td>{rateCard.description}</td>
                                <td>{rateCard.moniker}</td>
                            </tr>
                        )
                    })}
                </table>
            )}
        </div>
    )
}
