import React, { useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../auth/AuthProvider'
import { redirect } from 'react-router-dom'

export function AuthInterceptor() {
    const authService = useAuth()

    useEffect(() => {
        const responseInterceptor = axios.interceptors.response.use(
            null,
            function (error) {
                console.log(error.response.status === 401)
                if (
                    error.response.status === 401 ||
                    (error.response.status !== 200 &&
                        error.request.responseURL.includes('auth'))
                ) {
                    authService.logout()
                    redirect('/')
                }
                return Promise.reject(error)
            }
        )

        return () => {
            axios.interceptors.response.eject(responseInterceptor)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <></>
}
