import React from 'react'
import { FeeScheduleStatusSearch } from '../components/fee-schedule-status/FeeScheduleStatusSearch'
import { FeeScheduleStatusResults } from '../components/fee-schedule-status/FeeScheduleStatusResults'
import { PageHeader } from '../components/PageHeader'
import { Container } from '../components/Container'

export function FeeScheduleStatusView() {
    return (
        <Container>
            <PageHeader titleValue="Search fee schedule status" />
            <FeeScheduleStatusSearch />
            <FeeScheduleStatusResults />
        </Container>
    )
}
