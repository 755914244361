import { isEmptyArray } from './commonValidations'

export function populateSaleTypeOptions(saleTypeList) {
    if (!isEmptyArray(saleTypeList)) {
        const saleTypeOptions = []
        saleTypeOptions.push({ value: '', text: '--Select sale type--' })
        saleTypeList.forEach((saleType) => {
            saleTypeOptions.push({ value: saleType, text: saleType })
        })

        return saleTypeOptions
    }
    return null
}

export function populateFeeCodeOptions(feeCodeList) {
    if (!isEmptyArray(feeCodeList)) {
        const feeCodeOptions = []
        feeCodeOptions.push({ value: '', text: '--Select fee code--' })
        feeCodeList.forEach((feeCode) => {
            feeCodeOptions.push({ value: feeCode, text: feeCode })
        })

        return feeCodeOptions
    }
    return null
}

export function populateRateDescriptionOptions(rateDescriptionList) {
    if (!isEmptyArray(rateDescriptionList)) {
        const rateDescriptionOptions = [
            { value: '', text: '--Select Description--' }
        ]
        rateDescriptionList.forEach((description) => {
            let newOption = {
                value: description,
                text: description
            }
            rateDescriptionOptions.push(newOption)
        })

        return rateDescriptionOptions
    }
    return null
}

export function populateAuctionOptions(auctionList, includeCodeInDesc) {
    if (!isEmptyArray(auctionList)) {
        const auctionOptions = []
        auctionList.forEach(({ locationNameWithState, locationCode }) => {
            let text = locationNameWithState
            if (includeCodeInDesc) {
                text = `${text} (${locationCode})`
            }
            let newOption = {
                label: text,
                value: locationCode
            }
            auctionOptions.push(newOption)
        })

        return auctionOptions
    }
    return null
}

export function addToRateCardList(allRateCards, rateCard) {
    allRateCards.forEach((eachRateCard, index) => {
        if (eachRateCard.id === rateCard.id) {
            allRateCards[index] = rateCard
        }
    })
}

export function populateMultiAuctionOptions(auctionList, includeCodeInDesc) {
    if (!isEmptyArray(auctionList)) {
        const auctionOptions = []
        auctionOptions.push({ value: 'ALL', text: 'All Auctions' })
        auctionList.forEach(({ locationNameWithState, locationCode }) => {
            let text = locationNameWithState
            if (includeCodeInDesc) {
                text = `${text} (${locationCode})`
            }
            let newOption = {
                value: locationCode,
                text
            }
            auctionOptions.push(newOption)
        })

        return auctionOptions
    }
    return null
}

export function populateFeeScheduleStatusOptions() {
    const feeScheduleStatusOptions = []
    feeScheduleStatusOptions.push({ value: '', label: 'All' })
    feeScheduleStatusOptions.push({ value: 'PENDING', label: 'Pending' })
    feeScheduleStatusOptions.push({ value: 'PUBLISHED', label: 'Published' })

    return feeScheduleStatusOptions
}
