import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import uuid from 'uuid-js'
import { noOp } from './lambda.utils'

export function useAsyncValue(asyncSupplier, deps) {
    const [state, setState] = useState({ loading: true })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => asyncEffect(state, setState, asyncSupplier), deps)
    return state
}

export function useManualAsyncValue(asyncSupplier) {
    const [state, setState] = useState({})
    const [count, setCount] = useState(0)
    const updateCallback = useCallback(
        () => setCount(count + 1),
        [setCount, count]
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        () => (count ? asyncEffect(state, setState, asyncSupplier) : noOp),
        [count]
    )
    return [state, updateCallback]
}

export function useAsyncThen(asyncValue, asyncHandler) {
    const [state, setState] = useState({ loading: true })

    useEffect(() => {
        if (!state.loading) setState({ loading: true })

        return asyncValue.loading
            ? noOp
            : cancellableAsyncState(setState, () => asyncHandler(asyncValue))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asyncValue])

    return state
}

function asyncEffect({ loading }, setState, asyncSupplier) {
    if (!loading) setState({ loading: true })

    return cancellableAsyncState(setState, asyncSupplier)
}

function cancellableAsyncState(setState, asyncSupplier) {
    let cancelled = false

    asyncSupplier()
        .then((value) => !cancelled && setState({ value }))
        .catch((error) => !cancelled && setState({ error }))

    return () => (cancelled = true)
}

export function useEnumTranslation(prefix, fallback = '') {
    const { t } = useTranslation()

    return (value, translateData) =>
        t([`${prefix}.${value}`, fallback], translateData)
}

export function useManualRerender() {
    const [, setState] = useState(() => uuid.create())

    return useCallback(() => setState(uuid.create()), [setState])
}

export function useManualRerenderWithVersion() {
    const [version, setState] = useState(() => uuid.create().toString())
    const reRender = useCallback(
        () => setState(uuid.create().toString()),
        [setState]
    )
    return [version, reRender]
}

export function useTimeout(action, delay, deps) {
    const ref = useRef(NaN)

    useEffect(() => {
        ref.current = setTimeout(
            () => action((id) => (ref.current = id)),
            delay
        )

        return () => clearTimeout(ref.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}
