import { React } from 'react'
import { Grid } from '@interstate/components/Grid'

function PublicMenu() {
    return (
        <>
            <Grid
                container
                borderBottom={'1px solid'}
                boxShadow={'0px 2px #dddddd'}
                marginBottom={'15px'}>
                <Grid>
                    {' '}
                    <a href="/">
                        <img
                            src="/ca-logo.png"
                            alt="Cox Auto Logo"
                            style={{ height: '40px' }}
                        />
                    </a>
                </Grid>
            </Grid>
        </>
    )
}

export default PublicMenu
