import React from 'react'
import { FeeScheduleStatusSearch } from '../components/fee-schedule-status/FeeScheduleStatusSearch'
import { FeeScheduleStatusResults } from '../components/fee-schedule-status/FeeScheduleStatusResults'
import { useSearchParams } from 'react-router-dom'
import { Container } from '../components/Container'
import { PageHeader } from '../components/PageHeader'

export function FeeScheduleStatusResultsView() {
    let [searchParams] = useSearchParams()

    const groupCodeOrAccountNumber = searchParams.get('q')
    const startDate = searchParams.get('startDate')
    const endDate = searchParams.get('endDate')
    const feeScheduleStatus = searchParams.get('feeScheduleStatus')
        ? searchParams.get('feeScheduleStatus')
        : ''

    return (
        <Container>
            <PageHeader titleValue="Fee Schedule Status" />
            <FeeScheduleStatusSearch
                customerId={groupCodeOrAccountNumber}
                defaultStartDate={startDate}
                defaultEndDate={endDate}
                defaultFeeScheduleStatus={feeScheduleStatus}
            />
            {groupCodeOrAccountNumber && (
                <FeeScheduleStatusResults
                    customerId={groupCodeOrAccountNumber}
                    startDate={startDate}
                    endDate={endDate}
                    feeScheduleStatus={feeScheduleStatus}
                />
            )}
        </Container>
    )
}
