import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../auth/AuthProvider'
import PublicMenu from './navigation/PublicMenu'

function HomeLayout() {
    const authService = useAuth()

    if (authService.isAuthenticated()) {
        return <Navigate to="/main" />
    }

    return (
        <>
            <header className="App-header">
                <PublicMenu />
            </header>
            <div>
                <Outlet />
            </div>
        </>
    )
}

export default HomeLayout
