import { Grid } from '@interstate/components/Grid'
import React from 'react'

export function AuctionVehicleDataTable({ initData, vehicleData }) {
    return (
        <Grid container spacing={2}>
            {initData && (
                <Grid xs={12} md={3}>
                    <div>
                        <b>Location:</b>
                        {initData.buCode}
                    </div>
                    <div>
                        <b>SBLU:</b> {initData.sblu}
                    </div>
                    <div>
                        <b>Work Order:</b> {initData.workOrderNumber}
                    </div>
                    <div>
                        <b>Universal Key:</b>
                        {initData?.saleYear}-{initData?.saleNumber}-
                        {initData?.lane}-{initData?.runNumber}
                    </div>
                </Grid>
            )}
            <Grid xs={12} md={3}>
                {vehicleData?.body && (
                    <div>
                        <div>
                            <b>BUYER:</b> {vehicleData.body.buyer}
                        </div>
                        <div>
                            <b>BUYER Group:</b> {vehicleData.body.buyerGroup}{' '}
                            {vehicleData.body.buyerSubGroup}
                        </div>
                        <div>
                            <b>BUYER L/T:</b> {vehicleData.body.lane} /{' '}
                            {vehicleData.body.buyerFeeTable}
                        </div>
                        <div>
                            <b>BUYER fee:</b> {vehicleData.body.buyerFee}
                        </div>
                    </div>
                )}
            </Grid>
            <Grid xs={12} md={3}>
                {vehicleData?.body && (
                    <div>
                        <div>
                            <b>SELLER:</b> {vehicleData.body.seller}
                        </div>
                        <div>
                            <b>SELLER Group:</b> {vehicleData.body.sellerGroup}{' '}
                            {vehicleData.body.sellerSubGroup}{' '}
                        </div>
                        <div>
                            <b>SELLER L/T:</b> {vehicleData.body.lane} /{' '}
                            {vehicleData.body.sellerFeeTable}
                        </div>
                        <div>
                            <b>SELLER fee:</b> {vehicleData.body.sellerFee}
                        </div>
                    </div>
                )}
            </Grid>
            <Grid xs={12} md={3}>
                {vehicleData?.body && (
                    <div>
                        <div>
                            <b>VIN:</b> {vehicleData.body.vin}
                        </div>
                        <div>
                            <b>Sale Price:</b> {vehicleData.body.salePrice}
                        </div>
                        <div>
                            <b>Sale Date:</b> {vehicleData.body.saleDate}
                        </div>
                        <div>
                            <b>Channel:</b> {vehicleData.body.saleChannel}
                        </div>
                    </div>
                )}
            </Grid>
        </Grid>
    )
}
