import React, { useEffect, useState } from 'react'
import { clearServerCache } from '../../service/configService'

function ClearCache() {
    const [cacheClearComplete, setCacheClearComplete] = useState(false)

    useEffect(() => {
        clearServerCache().then(
            () => {
                setCacheClearComplete(true)
            },
            (error) => {
                console.error('error', error)
            }
        )
    }, [])

    return (
        <div>
            <h1>Clear Cache</h1>

            {cacheClearComplete ? (
                <div>Cache Clear Completed</div>
            ) : (
                <div>Cache Clear In Progress</div>
            )}
        </div>
    )
}

export default ClearCache
