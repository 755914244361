export class FeeScheduleModel {
    constructor() {
        this.feeScheduleType = null
        this.accountNumbers = null
        this.groupCodes = null
        this.altGroupCodes = null
        this.description = null
        this.startDate = null
        this.endDate = null
        this.saleTypeFeeList = null
    }

    setFeeScheduleType(feeScheduleType) {
        this.feeScheduleType = feeScheduleType
    }

    setAccountNumbers(accountNumbers) {
        let accountNumbersUpperCase = accountNumbers.map((accountNumber) =>
            accountNumber.toUpperCase()
        )
        this.accountNumbers = [...accountNumbersUpperCase]
    }

    setGroupCodes(groupCodes) {
        let groupCodesUpperCase = groupCodes.map((groupCode) =>
            groupCode.toUpperCase()
        )
        this.groupCodes = [...groupCodesUpperCase]
    }

    setAltGroupCodes(altGroupCodes) {
        let altGroupCodesUpperCase = altGroupCodes.map((altGroupCode) =>
            altGroupCode.toUpperCase()
        )
        this.altGroupCodes = [...altGroupCodesUpperCase]
    }

    setDescription(description) {
        this.description = description
    }

    setStartDate(startDate) {
        this.startDate = startDate
    }

    setEndDate(endDate) {
        this.endDate = endDate
    }

    setSaleTypeFeeList(saleTypeFeeList) {
        this.saleTypeFeeList = saleTypeFeeList
    }

    getObject() {
        return {
            feeScheduleType: this.feeScheduleType,
            accountNumbers: this.accountNumbers,
            groupCodes: this.groupCodes,
            altGroupCodes: this.altGroupCodes,
            description: this.description,
            startDate: this.startDate,
            endDate: this.endDate,
            saleTypeFeeList: this.saleTypeFeeList
        }
    }
}
