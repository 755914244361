import { Route, Routes } from 'react-router-dom'
import GetFeesTool from '../views/dev/GetFeesTool'
import AuditTestSources from '../views/dev/AuditTestSources'
import ImportAS400DataOneToOne from '../views/dev/ImportAS400DataOneToOne'
import ClearCache from '../views/dev/ClearCache'

function DevRoute() {
    return (
        <Routes>
            <Route exact path="getFeesTool" element={<GetFeesTool />} />
            <Route
                exact
                path="auditTestSources"
                element={<AuditTestSources />}
            />
            <Route
                exact
                path="import1to1AS400Data"
                element={<ImportAS400DataOneToOne />}
            />
            <Route exact path="clearCache" element={<ClearCache />} />
            <Route path=" " element={<div>Root Dev</div>} />
        </Routes>
    )
}

export default DevRoute
