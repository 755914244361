import React, { useState } from 'react'
import { getFeeAuctionSblu } from '../../service/feeScheduleService'
import { getVehicleAuctionSblu } from '../../service/auctionVehicleDataService'
import { Container } from '../../components/Container'
import { PageHeader } from '../../components/PageHeader'
import { Button } from '@interstate/components/Button'
import { Grid } from '@interstate/components/Grid'
import { LocationComboBox } from '../../components/common/LocationComboBox'
import { PatTextInput } from '../../components/common/PatTextInput'

function GetFeesTool() {
    const [sblu, setSblu] = useState('')
    const [auction, setAuction] = useState([{ value: '' }])
    const [feeData, setFeeData] = useState({})
    const [vehicleData, setVehicleData] = useState({})
    const [toggleRawData, setToggleRawData] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault()
        let av = auction
        if (typeof auction === 'object' && auction.length === 1) {
            av = auction[0]?.value
        }

        getFeeAuctionSblu(av, sblu).then((data) => {
            setFeeData(data)
        })

        getVehicleAuctionSblu(av, sblu)
            .then((data) => {
                setVehicleData(data)
            })
            .catch((error) => {
                setVehicleData(error.response.data)
            })
    }

    return (
        <Container>
            <PageHeader titleValue="Get Fees Tool" />
            <LocationComboBox
                value={auction}
                multiple={false}
                onChange={(e) => {
                    setAuction(e.target.value)
                }}
            />

            <PatTextInput
                label="SBLU"
                value={sblu}
                onChange={(e) => setSblu(e.target.value)}
            />
            <Button onClick={onSubmit} sx={{ marginTop: '10px' }}>
                Submit
            </Button>
            <Grid container sx={{ paddingTop: '10px' }}>
                <div>
                    <h2>Vehicle Data</h2>
                    {vehicleData?.body && (
                        <div>
                            <div>BUYER: {vehicleData.body.buyer}</div>
                            <div>
                                BUYER Group: {vehicleData.body.buyerGroup}{' '}
                                {vehicleData.body.buyerSubGroup}
                            </div>
                            <div>
                                BUYER L/T: {vehicleData.body.lane} /{' '}
                                {vehicleData.body.buyerFeeTable}
                            </div>
                            <div>BUYER fee: {vehicleData.body.buyerFee}</div>
                            <br />
                            <div>SELLER: {vehicleData.body.seller}</div>
                            <div>
                                SELLER Group: {vehicleData.body.sellerGroup}{' '}
                                {vehicleData.body.sellerSubGroup}{' '}
                            </div>
                            <div>
                                SELLER L/T: {vehicleData.body.lane} /{' '}
                                {vehicleData.body.sellerFeeTable}
                            </div>
                            <div>SELLER fee: {vehicleData.body.sellerFee}</div>
                        </div>
                    )}
                    <button
                        onClick={() => {
                            setToggleRawData(!toggleRawData)
                        }}>
                        Toggle Raw Data
                    </button>
                    {toggleRawData && (
                        <div>
                            <h3>Raw Data</h3>
                            <pre>{JSON.stringify(vehicleData, 0, 2)}</pre>
                        </div>
                    )}
                </div>
                <div>
                    <h2>Fee API Data</h2>
                    <pre>{JSON.stringify(feeData, 0, 2)}</pre>
                </div>
            </Grid>
        </Container>
    )
}

export default GetFeesTool
