import { Modal } from '@interstate/components/Modal'

export function AskToDelete({
    show,
    data,
    handle,
    cancel,
    attribute,
    deleteTestId,
    cancelTestId
}) {
    if (!data) {
        data = {}
        data[attribute] = attribute || ''
    }
    return (
        <Modal
            show={show}
            size={'sm'}
            footer={{
                primary: {
                    label: 'Delete',
                    action: handle,
                    'data-testid': `${deleteTestId}`
                },
                options: [
                    {
                        label: 'Cancel',
                        action: cancel,
                        'data-testid': `${cancelTestId}`
                    }
                ]
            }}>
            <div key=".0">
                <h4 marginBottom="5px">
                    Are you sure you want to delete "{data[attribute]}"?
                </h4>
            </div>
        </Modal>
    )
}
