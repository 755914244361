export class AuthService {
    constructor(principal) {
        this.principal = principal
    }

    name() {
        if (this.principal) {
            return `${this.principal.firstName} ${this.principal.lastName}`
        }
    }

    logout() {
        delete this.principal
    }

    isAuthenticated() {
        return this.hasToken()
        // return this.hasToken() && this.factorAuthenticated()
    }

    factorRequired() {
        return this.principal && this.principal.factorRequired
    }

    factors() {
        return (this.principal && this.principal.factors) || []
    }

    hasStateToken() {
        return this.principal && this.principal.hasStateToken
    }

    hasToken() {
        return this.hasSessionToken() || this.hasStateToken()
    }

    hasSessionToken() {
        return this.principal && this.principal.hasSessionToken
    }

    factorAuthenticated() {
        return this.principal && this.principal.factorAuthenticated
    }
}
