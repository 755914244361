import { React, useEffect, useState } from 'react'
import { SaleTypeMappingsTable } from './saleTypes/SaleTypeMappingsTable'
import { PageHeader } from '../components/PageHeader'
import { Container } from '../components/Container'
import { LocationComboBox } from '../components/common/LocationComboBox'

export function SaleTypeMappingsView(props) {
    const [selectedLocations, setSelectedLocations] = useState([])
    const [editMode, setEditMode] = useState(false)

    useEffect(() => {
        // console.log("selected locations changed: ", selectedLocations);
    }, [selectedLocations])

    return (
        <Container>
            <PageHeader titleValue="Sale Type Mapping" />
            <LocationComboBox
                value={selectedLocations}
                multiple={false}
                onChange={(e) => {
                    setSelectedLocations(e.target.value)
                }}
                disabled={editMode}
            />

            {selectedLocations &&
                selectedLocations.map((option) => (
                    <SaleTypeMappingsTable
                        key={option.value}
                        locationId={option.value}
                        label={option.text}
                        onEdit={() => {
                            setEditMode(!editMode)
                        }}
                    />
                ))}
        </Container>
    )
}
