import { React } from 'react'
import { RateCardDescriptions } from '../components/ratecard/RateCardDescriptions'
import { PageHeader } from '../components/PageHeader'

export function RateCardsView(props) {
    return (
        <div>
            <PageHeader titleValue="Rate Cards" />
            <div className="d-flex flex-row">
                <div className="mt-1">
                    <RateCardDescriptions />
                </div>
            </div>
        </div>
    )
}
