import { Button } from '@interstate/components/Button'
import { React } from 'react'
import { ThreeDotAction } from './ThreeDotAction'

export function SaleTypesTree({ data, onEditClick, onDeleteClick }) {
    const sorted = data.sort((a, b) => {
        if (a.name < b.name) {
            return -1
        }
        if (a.name > b.name) {
            return 1
        }
        return 0
    })

    function getRoots() {
        return sorted.filter((item) => !item['parent_id'])
    }

    function ChildrenOf({ arr, parent }) {
        return (
            <div style={{ marginLeft: '20px' }}>
                {arr
                    .filter((item) => item['parent_id'] === parent.id)
                    .map((child) => {
                        return (
                            <div key={child.id}>
                                <ThreeDotAction
                                    item={child}
                                    onEdit={onEditClick}
                                    onDelete={onDeleteClick}
                                    roles={['ADMIN', 'BOC_EDIT']}
                                />
                                {child.name}
                                <ChildrenOf arr={arr} parent={child} />
                            </div>
                        )
                    })}
            </div>
        )
    }

    return (
        <div>
            <div>
                {getRoots().map((root) => {
                    return (
                        <div key={root.id}>
                            {root.name}
                            <ChildrenOf arr={sorted} parent={root} />
                        </div>
                    )
                })}
            </div>
            <Button onClick={() => onEditClick({ id: 'NEW' })}>
                Add Sale Type
            </Button>
        </div>
    )
}
