import { DatePicker } from '@interstate/components/DatePicker'

export function PatDatePicker({ minToday, minDate, maxDays, ...props }) {
    let minDateVal = null
    if (minToday) {
        minDateVal = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            0,
            0,
            0,
            0
        )
    }

    if (minDate) {
        minDateVal = minDate
    }

    if (maxDays) {
        let maxDate = new Date(minDate)
        maxDate.setDate(maxDate.getDate() + maxDays - 1)
        props.maxDate = maxDate
    }

    return <DatePicker minDate={minDateVal} {...props} />
}
