import { React, useState } from 'react'
import { renderShortDate } from '../../utils/Formatters'
import { useSaleTypeMappings } from '../../service/configService'
import { Table } from '@interstate/components/Table'
import { ThreeDotAction } from '../../components/ThreeDotAction'
import { Alert } from '@interstate/components/Alert'
import { SaleTypesMappingEditForm } from './SaleTypesMappingEditForm'
import { Button } from '@interstate/components/Button'
import { RoleWrapper } from '../../components/RoleWrapper'

export function SaleTypeMappingsTable({
    label,
    onRowClick,
    locationId,
    onEdit
}) {
    const [editor, setEditor] = useState(null)

    const handleEdit = (row) => {
        setEditor(row)
        onEdit()
    }

    const {
        value: data,
        loading: dataLoading,
        error: dataError,
        reload: reloadData
    } = useSaleTypeMappings(locationId)
    data &&
        data.sort(
            (a, b) =>
                (a.lane || 0) - (b.lane || 0) ||
                (a.feeCategory || 0) - (b.feeCategory || 0)
        )

    return (
        <>
            {!editor && (
                <>
                    {dataError && (
                        <Alert type="error" title="Error">
                            Something went wrong!
                        </Alert>
                    )}
                    {!dataLoading && (
                        <Table
                            tableLayout={'auto'}
                            sortableColumns={true}
                            highlightOnHover={true}
                            headerBackgroundColor="dark"
                            enablePagination={false}
                            data={data}
                            columns={[
                                {
                                    title: 'Lane #',
                                    dataIndex: 'lane',
                                    render: (lane) => lane || 'Any'
                                },
                                {
                                    title: 'Table #',
                                    dataIndex: 'feeCategory',
                                    render: (feeCategory) =>
                                        feeCategory >= 0 ? feeCategory : 'Any'
                                },
                                { title: 'Sale Type', dataIndex: 'saleType' },
                                {
                                    title: 'Start Date',
                                    dataIndex: 'startTime',
                                    render: (startTime) =>
                                        renderShortDate(startTime)
                                },
                                {
                                    title: 'End Date',
                                    dataIndex: 'endTime',
                                    render: (endTime) =>
                                        renderShortDate(endTime)
                                },
                                {
                                    title: 'Description',
                                    dataIndex: 'comments'
                                },
                                {
                                    title: '',
                                    columnSortable: false,
                                    className: 'text-right',
                                    render: (row) => (
                                        <ThreeDotAction
                                            item={row}
                                            roles={[
                                                'ADMIN',
                                                'BOC_EDIT',
                                                'AUCTION_EDIT'
                                            ]}
                                            onEdit={handleEdit}
                                            onCancel={() => {
                                                setEditor(null)
                                            }}
                                            locationId={locationId}
                                        />
                                    )
                                }
                            ]}
                        />
                    )}
                    <RoleWrapper
                        roles={['ADMIN', 'BOC_EDIT', 'AUCTION_EDIT']}
                        locationId={locationId}>
                        <Button
                            onClick={() => {
                                handleEdit({ id: 'NEW' })
                            }}>
                            Add Mapping
                        </Button>
                    </RoleWrapper>
                </>
            )}
            {editor && (
                <SaleTypesMappingEditForm
                    data={editor}
                    locationId={locationId}
                    closeEdit={() => {
                        reloadData()
                        setEditor(null)
                        onEdit()
                    }}
                />
            )}
        </>
    )
}
