import { React, useState } from 'react'
import { SearchTransactionForm } from '../components/search/SearchTransactionForm'
import { SearchTransactionResults } from '../components/search/SearchTransactionResults'
import { Container } from '../components/Container'
import { PageHeader } from '../components/PageHeader'

export function SearchTransactionsView() {
    const [query, setQuery] = useState(null)
    return (
        <Container>
            <PageHeader titleValue="Search Transactions" />
            <SearchTransactionForm search={setQuery} />
            {query && <SearchTransactionResults query={query} />}
        </Container>
    )
}
