import { createContext, useContext } from 'react'
import { useStaticData } from '../service/staticDataService'

const StaticDataContext = createContext({})

export function useStaticDataContext() {
    return useContext(StaticDataContext)
}

export function StaticDataContextWrapper({ children }) {
    const {
        value: staticData,
        loading: staticDataLoading,
        error: staticDataError
    } = useStaticData()

    return (
        !staticDataLoading &&
        !staticDataError && (
            <StaticDataContext.Provider value={staticData}>
                {children}
            </StaticDataContext.Provider>
        )
    )
}
