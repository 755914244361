//"/macs/service/AuctionVehicleData/source/DALA/AuctionVehicleData?SBLU=21022695";

import axios from 'axios'

export function getVehicleAuctionSblu(auction, sblu) {
    return axios
        .get(`/api/getVehicleData?auction=${auction}&sblu=${sblu}`)
        .then(({ data }) => data)
}

export function getVehicleAuctionNotes(auction, wo) {
    return axios
        .get(`/api/getVehicleNotes?auction=${auction}&wo=${wo}`)
        .then(({ data }) => data)
}
