import { React, useState } from 'react'
import { useGetAllUsers, deleteUser } from '../service/configService'
import { useNavigate } from 'react-router-dom'
import { AskToDelete } from '../components/AskTo'
import { Table } from '@interstate/components/Table'
import { Button } from '@interstate/components/Button'
import { Container } from '../components/Container'
import { PageHeader } from '../components/PageHeader'
import { ThreeDotAction } from '../components/ThreeDotAction'

export function UserPermissionsView(props) {
    const navigate = useNavigate()
    const [getUsersTrigger, setGetUsersTrigger] = useState(0)
    const {
        value: users,
        loading: usersLoading,
        error: usersError
    } = useGetAllUsers(getUsersTrigger)

    const [showDelete, setShowDelete] = useState(false)
    const [userDelete, setUserDelete] = useState(null)

    const handleDelete = (userId) => {
        deleteUser(userId)
            .then(() => {
                console.log('deleted user ' + userId)
                setShowDelete(false)
                setUserDelete(null)
                setGetUsersTrigger(getUsersTrigger + 1)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <Container>
            <PageHeader titleValue="User Permissions" />
            <AskToDelete
                show={showDelete}
                setShow={setShowDelete}
                cancel={() => {
                    console.log('cancel delete')
                    setUserDelete(null)
                    setShowDelete(false)
                }}
                handle={() => {
                    handleDelete(userDelete.id)
                }}
                data={userDelete}
                attribute="username"
            />
            {usersError && <div>Error loading users</div>}
            {!usersLoading && users && (
                <Table
                    tableLayout={'auto'}
                    sortableColumns={true}
                    displayFilter={true}
                    highlightOnHover={true}
                    headerBackgroundColor="dark"
                    data={users}
                    columns={[
                        {
                            title: 'Username',
                            dataIndex: 'username'
                        },
                        {
                            title: 'Role',
                            dataIndex: 'role'
                        },
                        {
                            title: 'Location(s)',
                            dataIndex: 'locationId'
                        },
                        {
                            title: 'Date/Time Created',
                            dataIndex: 'created'
                        },
                        {
                            title: 'Created By',
                            dataIndex: 'createdBy'
                        },
                        {
                            title: 'Date/Time Updated',
                            dataIndex: 'updated'
                        },
                        {
                            title: 'Updated By',
                            dataIndex: 'updatedBy'
                        },
                        {
                            title: '',
                            className: 'text-right',
                            columnSortable: false,
                            render: (item) => (
                                <ThreeDotAction
                                    item={item}
                                    onEdit={(item) => {
                                        navigate(
                                            `/config/edit-user-permissions/${item.id}`
                                        )
                                    }}
                                    onDelete={(item) => {
                                        setUserDelete(item)
                                        setShowDelete(true)
                                    }}
                                    roles={['ADMIN', 'BOC_EDIT']}
                                />
                            )
                        }
                    ]}
                    defaultPageSize={10}
                />
            )}
            <Button
                data-testid="adduser"
                variant="primary"
                onClick={() => {
                    navigate('/config/add-user-permissions')
                }}>
                Add User
            </Button>
        </Container>
    )
}
