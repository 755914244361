import React from 'react'
import { ActionableFeeInfo } from './ActionableFeeInfo'
import { Modal } from '@interstate/components/Modal'

export function ExpireFeeScheduleModal({
    showExpireFeeModal,
    setShowExpireFeeModal,
    actionableFeeScheduleFlat,
    confirmExpireFeeSchedule
}) {
    return (
        <Modal
            data-testid="modal_ExpireFeeSchedule"
            size="small"
            show={showExpireFeeModal}
            header={
                <ActionableFeeInfo
                    feeScheduleFlat={actionableFeeScheduleFlat}
                    expireMode={true}></ActionableFeeInfo>
            }
            footer={{
                primary: {
                    label: 'Confirm',
                    action: () => {
                        confirmExpireFeeSchedule(actionableFeeScheduleFlat)
                    },
                    datatestid: 'button_ExpireFeeSchedule_Confirm'
                },
                options: [
                    {
                        label: 'Cancel',
                        action: () => {
                            setShowExpireFeeModal(false)
                        },
                        datatestid: 'button_ExpireFeeSchedule_Cancel'
                    }
                ]
            }}
        />
    )
}
