import { React } from 'react'
import { useRateCardDistinctDescriptions } from '../../service/rateCardService'
import { SelectInput } from '../InputControls'
import { useNavigate, createSearchParams } from 'react-router-dom'

export function RateCardDescriptions(props) {
    const navigate = useNavigate()
    // const [auctionCode, setAuctionCode] = useState('')

    const {
        value: descriptionList,
        loading: descriptionListLoading,
        error: descriptionListError
    } = useRateCardDistinctDescriptions()

    const descriptionOptions = [{ value: '', text: '--Select Description--' }]

    if (descriptionList && descriptionList.length > 0) {
        descriptionList.forEach((description) => {
            let newOption = {
                value: description,
                text: description
            }
            descriptionOptions.push(newOption)
        })
    }

    function onInputChange(event) {
        switch (event.target.name) {
            case 'rateCardDescription':
                navigate({
                    pathname: '/boc/ratecardResults',
                    search: `?${createSearchParams({
                        rateDescription: encodeURI(event.target.value)
                    })}`
                })
                break
            default:
                break
        }
    }

    return (
        <div>
            {!descriptionListLoading && !descriptionListError && (
                <span>
                    <SelectInput
                        name="rateCardDescription"
                        options={descriptionOptions}
                        onChange={onInputChange}></SelectInput>
                </span>
            )}
        </div>
    )
}
