import { useAuth } from '../auth/AuthProvider'
import { Container } from '../components/Container'
import { PageHeader } from '../components/PageHeader'

export function MainPageView() {
    const { principal } = useAuth()

    return (
        <Container>
            <PageHeader titleValue={`Welcome ${principal?.firstName}`} />
        </Container>
    )
}
