import { React, useState } from 'react'
import { useStaticDataContext } from '../context/StaticDataContext'
import { useAllProcessConfigs } from '../service/configService'
import { Container } from '../components/Container'
import { PageHeader } from '../components/PageHeader'
import { getConfigData, flattenData } from '../utils/processConfig.util'
import { AuctionConfigurationTable } from './auctionConfigurations/AuctionConfigurationTable'
import { LocationComboBox } from '../components/common/LocationComboBox'
import { auctionOptionsUtil } from '../utils/auction.util'
import { PatErrorAlert } from '../components/common/PatErrorAlert'

export function AuctionConfigurationsView() {
    const staticData = useStaticDataContext()
    const auctionOptions = auctionOptionsUtil(staticData)
    const [selectedLocations, setSelectedLocations] = useState([])
    const [trigger, setTrigger] = useState(0)

    const {
        value: configs,
        loading: configsLoading,
        error: configsError
    } = useAllProcessConfigs(trigger)

    let data = []
    if (auctionOptions && auctionOptions.length > 0) {
        if (configs && configs.length > 0) {
            const configData = getConfigData(configs, auctionOptions)
            data = flattenData(
                configData,
                selectedLocations.map((x) => x.value)
            )
        }
    }

    const handleEdit = (id) => {
        setTrigger(trigger + 1)
    }

    return (
        <Container>
            <PageHeader titleValue="Configurations" />

            <LocationComboBox
                value={selectedLocations}
                onChange={(e) => {
                    setSelectedLocations(e.target.value)
                }}
            />

            {configsError && (
                <PatErrorAlert
                    error={configsError}
                    text="Something went wrong!"
                />
            )}
            {!configsLoading && (
                <AuctionConfigurationTable data={data} onEdit={handleEdit} />
            )}
        </Container>
    )
}
