import React from 'react'
import { Table } from '@interstate/components/Table'
import { RateCardOnTooltip } from './RateCardOnTooltip'
import {
    describeFeeStructureOldFormat,
    mapRateCardToFeeStructure
} from '../../utils/Formatters'
import { InfoIcon } from '../Icons'
import { Popover } from '@interstate/components/Popover'

export function MatchedRateCardTable({
    matchingRateCardList,
    selectExistingRateCard
}) {
    function selectRateCard(rateCard) {
        selectExistingRateCard(rateCard)
    }

    return (
        <div className="matchintRateCards">
            <Table
                columns={[
                    {
                        className: 'column-description',
                        dataIndex: 'description',
                        render: (item) => <div>{item}</div>,
                        title: 'Description'
                    },
                    {
                        className: 'column-TiersFee',
                        render: (item) => (
                            <Popover
                                onOpen={function Ua() {}}
                                popoverContent={
                                    <RateCardOnTooltip
                                        feeStructure={mapRateCardToFeeStructure(
                                            item
                                        )}
                                    />
                                }
                                trigger="hover">
                                {describeFeeStructureOldFormat(item)}{' '}
                                <InfoIcon />
                            </Popover>
                        ),
                        title: 'Tiers: Low/High Fee'
                    },
                    {
                        className: 'column-useRateCard',
                        render: (item) => (
                            <div>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        selectRateCard(item)
                                    }}>
                                    Use Rate Card
                                </button>
                            </div>
                        ),
                        title: ''
                    }
                ]}
                data={matchingRateCardList}
                tableLayout={'auto'}
                enablePagination={true}
                id="overlappingFeesTable"
                onChangePage={function Ua() {}}
                onChangePageSize={function Ua() {}}
                onChangeSelectedRows={function Ua() {}}
                onChangeSorter={function Ua() {}}
                onExpandClick={function Ua() {}}
                onRowCallback={function Ua() {}}
                onRowSelect={function Ua() {}}
            />
        </div>
    )
}
