import { PatTextInput } from '../common/PatTextInput'
import { Grid } from '@interstate/components/Grid'
import { useEffect, useState } from 'react'
import { Button } from '@interstate/components/Button'
import { Card } from '@interstate/components/Card'
import { TextArea } from '@interstate/components/TextArea'
import { useGetManualFees } from '../../service/manualService'
import { useAuth } from '../../auth/AuthProvider'
import { PAT_ROLE } from '../../resources/enum.constants'

function FeeInputForm({ buyer, seller, intBuy, intSell, notes, onChange }) {
    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={3}>
                    <PatTextInput
                        label="Buyer"
                        name="buyer"
                        value={buyer}
                        onChange={onChange}
                    />
                </Grid>
                <Grid xs={3}>
                    <PatTextInput
                        label="Seller"
                        name="seller"
                        value={seller}
                        onChange={onChange}
                    />
                </Grid>
                <Grid xs={3}>
                    <PatTextInput
                        label="INTBUY"
                        name="intBuy"
                        value={intBuy}
                        onChange={onChange}
                    />
                </Grid>
                <Grid xs={3}>
                    <PatTextInput
                        label="INTSELL"
                        name="intSell"
                        value={intSell}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <TextArea
                        label="Notes"
                        maxLength={254}
                        value={notes}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
        </>
    )
}

function ManualFeeActions({ toggle, setToggle }) {
    return (
        <div style={{ margin: '10px' }}>
            <Button
                sx={{
                    marginRight: '10px'
                }}
                onClick={() => {
                    setToggle(!toggle)
                }}>
                Save
            </Button>
            <Button
                sx={{
                    marginRight: '10px',
                    backgroundColor: 'darkred'
                }}
                buttonStyle={'danger'}
                onClick={() => {
                    setToggle(!toggle)
                }}>
                Remove
            </Button>
            <Button
                buttonStyle={'secondary'}
                onClick={() => {
                    setToggle(!toggle)
                }}>
                Cancel
            </Button>
        </div>
    )
}

export function ManualFeesForm({ bucode, sblu }) {
    const [toggle, setToggle] = useState(false)
    const [buyer, setBuyer] = useState(null)
    const [seller, setSeller] = useState(null)
    const [intBuy, setIntBuy] = useState(null)
    const [intSell, setIntSell] = useState(null)
    const [notes, setNotes] = useState(null)

    const authService = useAuth()
    const principal = authService.principal
    const role = principal?.role
    const show = role === PAT_ROLE.ADMIN || role === PAT_ROLE.BOC_EDIT

    const {
        value: manualFees,
        loading: manualLoading,
        error: manualError
    } = useGetManualFees(bucode, sblu)

    useEffect(() => {
        if (!manualFees && manualFees?.length > 0) return
        let notes = null
        manualFees?.forEach((fee) => {
            switch (fee.fee_category) {
                case 'BUYER':
                    setBuyer(fee.amount)
                    break
                case 'SELLER':
                    setSeller(fee.amount)
                    break
                case 'INTBUY':
                    setIntBuy(fee.amount)
                    break
                case 'INTSELL':
                    setIntSell(fee.amount)
                    break
                default:
            }
            if (!notes && fee?.description) {
                notes = fee.description
            }
        })
        setNotes(notes)
    }, [manualFees])

    const onChange = (e) => {
        switch (e.target.name) {
            case 'buyer':
                setBuyer(e.target.value)
                break
            case 'seller':
                setSeller(e.target.value)
                break
            case 'intBuy':
                setIntBuy(e.target.value)
                break
            case 'intSell':
                setIntSell(e.target.value)
                break
            case 'notes':
                setNotes(e.target.value)
                break
            default:
                break
        }
    }
    return (
        <>
            {show && (
                <>
                    {!toggle && (
                        <Button
                            onClick={() => {
                                setToggle(!toggle)
                            }}>
                            Manual Fee
                        </Button>
                    )}
                    {toggle && (
                        <>
                            <Card
                                header={<h2>Manual Fees</h2>}
                                content={
                                    <FeeInputForm
                                        buyer={buyer}
                                        seller={seller}
                                        intBuy={intBuy}
                                        intSell={intSell}
                                        notes={notes}
                                        onChange={onChange}
                                    />
                                }
                                footer={
                                    <ManualFeeActions
                                        toggle={toggle}
                                        setToggle={setToggle}
                                    />
                                }
                            />
                        </>
                    )}
                </>
            )}
        </>
    )
}
