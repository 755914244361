import { Table } from '@interstate/components/Table'
import { useState } from 'react'

export function FeeStructureColumn({ feeStructure }) {
    const [expand = true, setExpand] = useState(false)

    return (
        <div>
            {!expand && <button onClick={() => setExpand(true)}>Expand</button>}
            {expand && (
                <div>
                    <button onClick={() => setExpand(false)}>Collapse</button>
                    <div>Add Fee: {feeStructure?.addFeesAmount}</div>
                    <div>Fee Unit: {feeStructure?.feeUnit}</div>
                    <div>Sale Price Limit: {feeStructure?.salesPriceLimit}</div>
                    <div>Fee Max: {feeStructure?.feeMaximum}</div>
                    <div>Fee Pct: {feeStructure?.feePercent}</div>
                    <Table
                        enablePagination={false}
                        columns={[
                            {
                                title: 'Fee',
                                render: (item) => <span>{item.fee}</span>
                            },
                            {
                                title: 'High',
                                render: (item) => <span>{item.highLimit}</span>
                            }
                        ]}
                        data={feeStructure.feeTiers}
                    />
                </div>
            )}
        </div>
    )
}
