import { React, useEffect, useState, memo, useMemo, useCallback } from 'react'
import { SearchFees } from '../components/search/SearchFees'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    deleteFeeSchedule,
    expireFeeSchedule,
    updateFeeSchedule,
    useSearchFee
} from '../service/feeScheduleService'
import { PageHeader } from '../components/PageHeader'
import { Container } from '../components/Container'
import { PatErrorAlert } from '../components/common/PatErrorAlert'
import { setStorage } from '../utils/storageService'
import { FEE_SCHEDULE_STORAGE_KEY } from '../utils/constants'
import {
    isDateAfterToday,
    getTodayDateInFormat
} from '../utils/date.helper.util'
import { ExpireFeeScheduleModal } from '../components/search/ExpireFeeScheduleModal'
import { generateRateCardDescription } from '../utils/rate.card.helper'
import {
    getCustomerTypeFromValue,
    trimAltGroupCode
} from '../utils/customer.helper.util'
import { EditEndDatePopupModal } from '../components/search/EditEndDatePopupModal'
import { EditExpireSuccessFailure } from '../components/search/EditExpireSuccessFailure'
import { DeleteFeeScheduleModal } from '../components/search/DeleteFeeScheduleModal'
import SearchResultsTable from '../components/search/SearchResultsTable'
import { getFeeScheduleFlatForCSV } from '../utils/fee.schedule.helper'
import { CsvExport } from '../components/search/CsvExport'
import { Grid } from '@interstate/components/Grid'
import { Typography } from '@interstate/components/Typography'

export function FeeResultsView(props) {
    const navigate = useNavigate()

    const [showExpireFeeModal, setShowExpireFeeModal] = useState(false)
    const [showDeleteFeeModal, setShowDeleteFeeModal] = useState(false)
    const [showEditEndDatePopupModal, setShowEditEndDatePopupModal] =
        useState(false)
    const [showSuccessFailureModal, setShowSuccessFailureModal] =
        useState(false)
    const [successMode, setSuccessMode] = useState(false)
    const [actionableFeeScheduleFlat, setActionableFeeScheduleFlat] = useState()
    const [searchResultsTableData, setSearchResultsTableData] = useState([])
    const [errorMessage, setErrorMessage] = useState()

    const [queryParams] = useSearchParams()
    let feeScheduleTypes = queryParams.get('feeScheduleTypes')
    let auctionCodes = queryParams.get('auctionCodes')
    let saleTypes = queryParams.get('saleTypes')
    let feeTypes = queryParams.get('feeTypes')
    let accounts = queryParams.get('accounts')
    let groupCodes = queryParams.get('groupCodes')
    let altGroupCodes = queryParams.get('altGroupCodes')
    let startDate = queryParams.get('startDate')
    let endDate = queryParams.get('endDate')
    let description = queryParams.get('description')
    let includeSaleTypeParent = queryParams.get('includeSaleTypeParent')
    let includeDeletedExpired = queryParams.get('includeDeletedExpired')
    let anyCustomersOnly = queryParams.get('anyCustomersOnly')
    let noCustomersOnly = queryParams.get('noCustomersOnly')

    const {
        value: searchFeeResults,
        loading: searchFeeLoading,
        error: searchFeeError
    } = useSearchFee({
        feeScheduleTypes,
        auctionCodes,
        saleTypes,
        feeTypes,
        accounts,
        groupCodes,
        altGroupCodes,
        startDate,
        endDate,
        description,
        includeSaleTypeParent,
        includeDeletedExpired,
        anyCustomersOnly,
        noCustomersOnly
    })

    useEffect(() => {
        // console.log('useEffect searchFeeResults', searchFeeResults)
        if (searchFeeResults && !searchFeeLoading && !searchFeeError) {
            setSearchResultsTableData(searchFeeResults)
        }
    }, [searchFeeResults])

    // useEffect(() => {
    //     console.log('inside useEffect of searchResultsTableData', new Date())
    // }, [searchResultsTableData])

    let searchResultsFeeScheduleFlatCsv = useMemo(() => {
        return getFeeScheduleFlatForCSV(searchResultsTableData)
    }, [searchResultsTableData])

    // const [consolidate, setConsolidate] = useState(true)

    // const handleConsolidateChange = (evt) => {
    //     setConsolidate(evt.target.checked)
    // }

    // function displayFeeStructure(saleTypeFee) {
    //     return (
    //         <Tooltip
    //             content={
    //                 <RateCardOnTooltip
    //                     feeStructure={saleTypeFee.feeStructure}
    //                 />
    //             }
    //             direction="bottom">
    //             <span>
    //                 {describeFeeStructureTierFormat(saleTypeFee.feeStructure)}
    //                 &nbsp;
    //                 <InfoIcon />
    //             </span>{' '}
    //             &nbsp;
    //         </Tooltip>
    //     )
    // }

    // const simpleTestFunction = useCallback(() => {
    //     console.log('simpleTestFunction')
    // }, [])

    const showExpireFeeSchedule = useCallback(
        (feeStructureFlat) => {
            setShowExpireFeeModal(true)
            setActionableFeeScheduleFlat(feeStructureFlat)
        },
        [searchResultsTableData]
    )

    const showDeleteFeeSchedule = useCallback(
        (feeStructureFlat) => {
            setShowDeleteFeeModal(true)
            setActionableFeeScheduleFlat(feeStructureFlat)
        },
        [searchResultsTableData]
    )

    function updateSearchResultsTableData(
        updatedFeeScheduleFlat,
        expireMode,
        deleteMode
    ) {
        let newSearchResultsTableData = []
        searchResultsTableData.forEach((feeScheduleFlat) => {
            if (feeScheduleFlat.id === updatedFeeScheduleFlat.id) {
                if (expireMode) {
                    newSearchResultsTableData.push(updatedFeeScheduleFlat)
                } else if (deleteMode) {
                    // do nothing, remove the record from the table
                }
            } else {
                newSearchResultsTableData.push(feeScheduleFlat)
            }
        })
        setSearchResultsTableData(newSearchResultsTableData)
        setSuccessMode(true)
        setShowSuccessFailureModal(true)
    }

    function confirmExpireFeeSchedule(feeStructureFlat) {
        expireFeeSchedule(feeStructureFlat).then(
            (response) => {
                setShowExpireFeeModal(false)
                let expiredFeeScheduleFlat = response.data
                updateSearchResultsTableData(
                    expiredFeeScheduleFlat,
                    true,
                    false
                )
            },
            (error) => {
                console.log('error', error)
                setSuccessMode(false)
                setShowSuccessFailureModal(true)
            }
        )
    }

    function confirmDeleteFeeSchedule(feeStructureFlat) {
        deleteFeeSchedule(feeStructureFlat).then(
            (response) => {
                setShowDeleteFeeModal(false)
                let deletedFeeScheduleFlat = response.data
                updateSearchResultsTableData(
                    deletedFeeScheduleFlat,
                    false,
                    true
                )
                setSuccessMode(true)
                setShowSuccessFailureModal(true)
            },
            (error) => {
                console.log('error', error)
                setSuccessMode(false)
                setShowSuccessFailureModal(true)
            }
        )
    }

    const editFeeSchedule = useCallback(
        (feeScheduleFlat) => {
            if (isDateAfterToday(feeScheduleFlat.startDate, 'YYYY-MM-DD')) {
                copyFeeSchedule(feeScheduleFlat)
            } else {
                setActionableFeeScheduleFlat(feeScheduleFlat)
                setShowEditEndDatePopupModal(true)
            }
        },
        [searchResultsTableData]
    )

    /**
     * only enddate and description will be saved in the backend
     * @param feeScheduleFlat
     */
    function saveEditFeeSchedule(feeScheduleFlat) {
        updateFeeSchedule(feeScheduleFlat)
            .then((response) => {
                setSuccessMode(true)
                setShowEditEndDatePopupModal(false)
                setShowSuccessFailureModal(true)
                let updatedFeeScheduleFlat = response.data
                updateSearchResultsTableData(
                    updatedFeeScheduleFlat,
                    true,
                    false
                )
            })
            .catch((error) => {
                setSuccessMode(false)
                console.log('error', error)
                setShowEditEndDatePopupModal(false)
                setShowSuccessFailureModal(true)
            })
    }

    const memoizedCopyFeeSchedule = useCallback(copyFeeSchedule, [
        searchResultsTableData
    ])

    function copyFeeSchedule(feeScheduleFlat) {
        let rateCardType = 'Tiered'
        if (feeScheduleFlat.feeStructure.feeTiers.length === 1) {
            rateCardType = 'FlatFee'
        }
        let feeTiers = []
        feeScheduleFlat.feeStructure.feeTiers.forEach((tier) => {
            feeTiers.push({
                lowLimit: tier.lowLimit,
                highLimit: tier.highLimit,
                fee: tier.fee
            })
        })

        let clonedFeeSchedule = {
            feeScheduleType: feeScheduleFlat.contractType,
            startDate: new Date().toISOString(),
            description: null,
            saleTypeFeeList: [
                {
                    auctionCodeList: [feeScheduleFlat.buCode],
                    saleType: feeScheduleFlat.saleType,
                    feeCode: feeScheduleFlat.feeType,
                    rateCardType: rateCardType,
                    rateCardDesc: generateRateCardDescription(feeTiers),
                    excludedAuctionList: null,
                    overrides: null,
                    feeStructure: {
                        salesPriceLimit:
                            feeScheduleFlat.feeStructure.salesPriceLimit,
                        addFeesAmount:
                            feeScheduleFlat.feeStructure.addFeesAmount,
                        feeUnit: feeScheduleFlat.feeStructure.feeUnit,
                        feeMaximum: feeScheduleFlat.feeStructure.feeMaximum,
                        feePercent: feeScheduleFlat.feeStructure.feePercent,
                        feeTiers: feeTiers
                    }
                }
            ]
        }

        switch (getCustomerTypeFromValue(feeScheduleFlat.customer)) {
            case 'accountNumber':
                clonedFeeSchedule.accountNumbers = [feeScheduleFlat.customer]
                break
            case 'groupCode':
                clonedFeeSchedule.groupCodes = [feeScheduleFlat.customer]
                break
            case 'altGroupCode':
                let altGroupCode = trimAltGroupCode(feeScheduleFlat.customer)
                clonedFeeSchedule.altGroupCodes = [altGroupCode]
                break
            default:
                break
        }
        setStorage(FEE_SCHEDULE_STORAGE_KEY, JSON.stringify(clonedFeeSchedule))
        navigate('/boc/addFeeSchedule')
    }

    function getCsvFileName() {
        return `SearchResults_Fee_Schedules_${getTodayDateInFormat('MM_DD_YYYY')}.csv`
    }

    return (
        <Container>
            <PageHeader titleValue="Fee Schedules" />
            {errorMessage && <PatErrorAlert error={true} text={errorMessage} />}
            {/*<div>*/}
            {/*    <h1></h1>*/}
            {/*</div>*/}
            <SearchFees />

            <Grid
                container
                justifyContent="space-between"
                marginBottom="15px"
                marginTop="15px">
                <Grid item></Grid>
                <Grid item>
                    <CsvExport
                        csvData={searchResultsFeeScheduleFlatCsv}
                        fileName={getCsvFileName()}
                    />
                </Grid>
            </Grid>

            <div>
                {!searchFeeLoading && !searchFeeError && (
                    <>
                        <span>
                            Total rows:{' '}
                            <strong>{searchResultsTableData.length}</strong>
                        </span>
                        <div>&nbsp;</div>
                        <SearchResultsTable
                            searchResultsTableData={searchResultsTableData}
                            showExpireFeeSchedule={showExpireFeeSchedule}
                            showDeleteFeeSchedule={showDeleteFeeSchedule}
                            editFeeSchedule={editFeeSchedule}
                            copyFeeSchedule={memoizedCopyFeeSchedule}
                        />
                    </>
                )}
            </div>

            <DeleteFeeScheduleModal
                actionableFeeScheduleFlat={actionableFeeScheduleFlat}
                confirmDeleteFeeSchedule={confirmDeleteFeeSchedule}
                setShowDeleteFeeModal={setShowDeleteFeeModal}
                showDeleteFeeModal={showDeleteFeeModal}
            />

            <ExpireFeeScheduleModal
                actionableFeeScheduleFlat={actionableFeeScheduleFlat}
                confirmExpireFeeSchedule={confirmExpireFeeSchedule}
                setShowExpireFeeModal={setShowExpireFeeModal}
                showExpireFeeModal={showExpireFeeModal}
            />
            <EditEndDatePopupModal
                actionableFeeScheduleFlat={actionableFeeScheduleFlat}
                saveEditFeeSchedule={saveEditFeeSchedule}
                setShowEditEndDatePopupModal={setShowEditEndDatePopupModal}
                showEditEndDatePopupModal={showEditEndDatePopupModal}
            />
            <EditExpireSuccessFailure
                showSuccessFailureModal={showSuccessFailureModal}
                setShowSuccessFailureModal={setShowSuccessFailureModal}
                successMode={successMode}
                failureMode={!successMode}
            />
        </Container>
    )
}
