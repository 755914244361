import { PAT_ROLE } from '../resources/enum.constants'
export function auctionDisplayUtil(code, context) {
    let display = ''
    let notfound = true
    context?.auctions.every((auction) => {
        if (auction.locationCode === code) {
            display = `${auction.state}-${auction.locationName} (${auction.locationCode})`
            notfound = false
        }
        return notfound
    })
    return display
}

export function auctionDisplayWithCode(code, auctionInfoMap) {
    // console.log('code', code)
    if (auctionInfoMap[code]) {
        return `${code}-${auctionInfoMap[code].locationName}`
    } else {
        return code
    }
}

export function sortByAuctionsUtil(auctions, context, param = 'auctionId') {
    return auctions.sort((a, b) => {
        let fa = auctionDisplayUtil(a[param], context),
            fb = auctionDisplayUtil(b[param], context)

        if (fa < fb) {
            return -1
        }
        if (fa > fb) {
            return 1
        }
        return 0
    })
}

function filterAuctionList(auctions, location) {
    let ids = location.split(',')
    return auctions.filter((a) => ids.includes(a.value))
}

export function auctionOptionsUtil(context, all = false, auth = null) {
    let auctions = context?.auctions
        ?.map((auction) => {
            return {
                value: auction.locationCode,
                text: `${auction.state}-${auction.locationName} (${auction.locationCode})`,
                label: `${auction.state}-${auction.locationName} (${auction.locationCode})`
            }
        })
        .sort((a, b) => a.label.localeCompare(b.label))

    if (auth) {
        if (auth.role === PAT_ROLE.AUCTION_EDIT) {
            return filterAuctionList(auctions, auth.locationId)
        }
        if (auth.role === PAT_ROLE.VIEW_ROLE) {
            if (auth.locationId) {
                return filterAuctionList(auctions, auth.locationId)
            } else return auctions
        }
    }

    if (all) {
        return [{ value: 'ALL', text: 'ALL', label: 'ALL' }, ...auctions]
    } else {
        return auctions
    }
}
